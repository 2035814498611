import { Component, HostListener, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { AduraService } from '@app/shared/services/adura.service';
import { TranslateService } from '@ngx-translate/core';
import { AduraGefahrCreateComponent } from '@app/adura/shared/gefahr-create/adura-gefahr-create.component';
import { AduraTreiberCreateComponent } from '@app/adura/shared/treiber-create/adura-treiber-create.component';
import { AduraPublikationAddComponent } from '@app/adura/shared/publikation/publikation-add/adura-publikation-add.component';
import { AduraMatrixEditComponent } from '@app/adura/shared/matrix/matrix-edit/adura-matrix-edit.component';
import { AduraMeldungWeitereInfoComponent } from '@app/adura/meldung/meldung-weitere-info/adura-meldung-weitere-info.component';
import { AduraBeurteilungEditComponent } from '@app/adura/shared/beurteilung/beurteilung-edit/adura-beurteilung-edit.component';
import { AduraAktivitaetEditComponent } from '@app/adura/shared/aktivitaet/aktivitaet-edit/adura-aktivitaet-edit.component';
import { Gefahr } from '@app/shared/classes/adura/gefahr';
import { Bereich } from '@app/shared/classes/adura/bereich';
import { Matrix } from '@app/shared/classes/adura/matrix';
import { Meldung } from '@app/shared/classes/adura/meldung';
import { PublikationDetail } from '@app/shared/classes/adura/publikation';
import { Status } from '@app/shared/classes/adura/status';
import { Steckbrief } from '@app/shared/classes/adura/steckbrief';
import { AduraDocument, DocumentState } from '@app/shared/classes/adura/adura-document';
import { Beurteilung } from '@app/shared/classes/adura/beurteilung';
import { Aktivitaet } from '@app/shared/classes/adura/aktivitaet';
import { Priority } from '@app/shared/classes/adura/priority';
import Swal from 'sweetalert2';
import * as dayjs from 'dayjs';
import { SteckbriefService } from '@app/shared/services/steckbrief.service';
import { firstValueFrom, forkJoin } from 'rxjs';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

@Component({
  selector: 'app-adura-steckbrief-edit',
  templateUrl: './adura-steckbrief-edit.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AduraSteckbriefEditComponent implements OnInit {
  steckbrief: Steckbrief;
  steckbriefForm: UntypedFormGroup;

  // Stammdaten
  bereichList: Bereich[];
  gefahrList: Gefahr[];
  statusList: Status[];
  meldungList: Meldung[];
  priorityList: Priority[];

  isSending = false;
  error = false;
  readyToUpload = true;
  delChosen = false;
  deleteError = false;
  errorMessages = [];
  disableInputByRole = false;

  tinyConfigTitel: any;
  tinyConfigEnterAndLinksAllowed: any;

  remainingCharactersMap = {
    titel: '',
    comment: ''
  };

  maxCharsMap = {
    titel: 255,
    comment: 4000
  };

  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: 'de'
  };
  selectedCreationDate: dayjs.Dayjs;
  creationDate: string;
  selectedNextEvaluation: dayjs.Dayjs;
  nextEvaluation: string;
  dropzoneConfig: DropzoneConfigInterface;
  dropzoneError: string[] = [];

  @ViewChild(AduraGefahrCreateComponent) gefahrCreate: AduraGefahrCreateComponent;
  @ViewChild(AduraTreiberCreateComponent) treiberCreate: AduraTreiberCreateComponent;
  @ViewChild(AduraPublikationAddComponent) publikationAdd: AduraPublikationAddComponent;
  @ViewChild(AduraMatrixEditComponent) matrixEdit: AduraMatrixEditComponent;
  @ViewChild(AduraBeurteilungEditComponent) beurteilungEdit: AduraBeurteilungEditComponent;
  @ViewChild(AduraAktivitaetEditComponent) aktivitaetEdit: AduraAktivitaetEditComponent;
  @ViewChild(AduraMeldungWeitereInfoComponent) weitereInfoEdit: AduraMeldungWeitereInfoComponent;
  @ViewChild(DropzoneComponent) dropzone: DropzoneComponent;

  constructor(private zone: NgZone,
              private aduraService: AduraService,
              private translateService: TranslateService,
              private authenticationService: AuthenticationService,
              private steckbriefService: SteckbriefService,
              private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    const steckbriefCode = this.route.snapshot.paramMap.get('code');

    console.log('Steckbrief-Code: ');
    console.log(steckbriefCode);

    this.disableInputsByRole();
    this.initForm();
    this.setupTinyMce();
    this.datePickerConfig.locale = this.translateService.currentLang;

    if (this.steckbriefService.steckbrief) {
      this.steckbrief = this.steckbriefService.steckbrief;
      this.updateForm();
      this.redirectByStatus();
    } else if (steckbriefCode === null) {
      this.steckbrief = new Steckbrief();
      this.steckbrief.documents = [];
      this.updateForm();
      this.redirectByStatus();
    } else {
      this.aduraService.getSteckbrief(steckbriefCode)
        .subscribe(steckbrief => {
          this.steckbrief = steckbrief;
          this.updateForm();
          this.redirectByStatus();
        });
    }

    this.aduraService.listBereich()
      .subscribe(
        (result) => {
          this.bereichList = result;
        });
    this.aduraService.listGefahr()
      .subscribe(
        (result) => {
          this.gefahrList = result;
        });
    this.aduraService.listSteckbriefStatus(steckbriefCode)
      .subscribe(
        (result) => {
          this.statusList = result;
        });
    this.aduraService.listSteckbriefPriority()
      .subscribe(
        (result) => {
          this.priorityList = result;
        });
    this.aduraService.listMeldungMinimal()
      .subscribe(
        (result) => {
          this.meldungList = result;

          this.steckbrief?.meldungen?.forEach(linkedMeldung =>
            this.meldungList.find(meldung => meldung.id === linkedMeldung.id).disabled = true
          );
        });

    Promise.all([
      this.authenticationService.getAccessToken(),
      firstValueFrom(this.aduraService.listAllowedMimeTypes())
    ]).then(([authToken, acceptedFiles]) => {
      const headers: any = {};
      if (authToken) {
        headers.Authorization = 'Bearer ' + authToken;
      }
      this.dropzoneConfig = {
        url: this.aduraService.temporaryDocumentUrl,
        method: 'post',
        maxFilesize: 10,
        acceptedFiles,
        headers
      };
    });
  }

  private setupTinyMce(): void {
    this.tinyConfigTitel = {
      menubar: false,
      toolbar: 'undo bold italic',
      valid_elements: 'strong,em',
      entity_encoding: 'raw',
      setup: this.preventEnterInTinyMceEditor
    };

    this.tinyConfigEnterAndLinksAllowed = {
      menubar: false,
      plugins: 'lists link',
      toolbar: 'undo bold italic bullist link',
      valid_elements: 'strong,em,br,a[href|target=_blank],ul,li',
      default_link_target: '_blank',
      target_list: false,
      entity_encoding: 'raw',
      convert_urls: false
    };
  }

  private initForm() {
    this.steckbriefForm = this.formBuilder.group({
      isPublic: [false],
      titel: [''],
      creationDate: [dayjs(new Date())],
      kurzinfo: [''],
      comment: [''],
      nextEvaluation: [{value: '', disabled: this.disableInputByRole}],
      signal: [{value: '', disabled: this.disableInputByRole}],
      priority: [null],
      beurteilungen: new UntypedFormArray([]),
      aktivitaeten: new UntypedFormArray([]),
      file: [''],
      documentName: [''],
      referenzen: [''],
      bcgDokumente: [''],
      gefahren: [[]],
      bereiche: new UntypedFormArray([]),
      matrix: new UntypedFormArray([]),
      documents: new UntypedFormArray([]),
      meldungen: [[]],
      status: [null],
      logComment: [null]
    });
  }

  private updateForm() {
    this.steckbriefForm.reset({
      isPublic: this.steckbrief.isPublic,
      titel: this.steckbrief.titel,
      creationDate: dayjs(this.steckbrief.creationDate),
      kurzinfo: this.steckbrief.kurzinfo,
      comment: this.steckbrief.comment,
      signal: this.steckbrief.signal,
      priority: this.steckbrief.priority,
      referenzen: this.steckbrief.referenzen,
      bcgDokumente: this.steckbrief.bcgDokumente,
      gefahren: this.steckbrief.gefahren,
      meldungen: this.steckbrief.meldungen,
      status: this.steckbrief.status,
      logComment: this.steckbrief.logComment
    });

    if (this.steckbrief.nextEvaluation !== undefined) {
      this.steckbriefForm.patchValue({ nextEvaluation: dayjs(this.steckbrief.nextEvaluation) });
    }

    const beurteilungenFormArray = this.steckbriefForm.get('beurteilungen') as UntypedFormArray;
    this.setFormArrayValues(beurteilungenFormArray, this.steckbrief.beurteilungen);

    const aktivitaetenFormArray = this.steckbriefForm.get('aktivitaeten') as UntypedFormArray;
    this.setFormArrayValues(aktivitaetenFormArray, this.steckbrief.aktivitaeten);

    const bereicheFormArray = this.steckbriefForm.get('bereiche') as UntypedFormArray;
    this.setFormArrayValues(bereicheFormArray, this.steckbrief.bereiche);

    const matrixFormArray = this.steckbriefForm.get('matrix') as UntypedFormArray;
    this.setFormArrayValues(matrixFormArray, this.steckbrief.matrix);

    const documentsFormArray = this.steckbriefForm.get('documents') as UntypedFormArray;
    this.setFormArrayValues(documentsFormArray, this.steckbrief.documents);

    this.steckbriefForm.markAsPristine();
  }

  private setFormArrayValues(formArray: UntypedFormArray, objectArray: any[]) {
    objectArray?.forEach(weitereInfo =>
      formArray.push(new UntypedFormControl(weitereInfo))
    );
  }

  saveSteckbrief() {
    this.steckbriefForm.markAsPristine();
    this.isSending = true;
    const formValues = this.steckbriefForm.value;

    const steckbrief = new Steckbrief();
    steckbrief.id = this.steckbrief.id;
    steckbrief.code = this.steckbrief.code;
    steckbrief.isPublic = formValues.isPublic || false;
    steckbrief.titel = formValues.titel;
    steckbrief.creationDate = new Date(formValues.creationDate);
    steckbrief.kurzinfo = formValues.kurzinfo;
    steckbrief.comment = formValues.comment;
    steckbrief.nextEvaluation = formValues.nextEvaluation ? new Date(formValues.nextEvaluation) : null;
    steckbrief.priority = formValues.priority;
    steckbrief.signal = formValues.signal || false;
    steckbrief.beurteilungen = this.steckbriefForm.get('beurteilungen').value;
    steckbrief.referenzen = formValues.referenzen;
    steckbrief.bcgDokumente = formValues.bcgDokumente;
    steckbrief.bereiche = this.steckbriefForm.get('bereiche').value;
    steckbrief.matrix = this.steckbriefForm.get('matrix').value;
    steckbrief.gefahren = this.steckbriefForm.get('gefahren').value;
    steckbrief.aktivitaeten = this.steckbriefForm.get('aktivitaeten').value;
    steckbrief.documents = this.steckbrief.documents;
    steckbrief.meldungen = this.steckbriefForm.get('meldungen').value;
    steckbrief.status = formValues.status;
    steckbrief.logComment = formValues.logComment;
    steckbrief.aduraId = this.steckbrief.aduraId;

    console.log(steckbrief);

    this.aduraService.saveSteckbrief(steckbrief)
      .subscribe({
        next: result => {
          steckbrief.code = result.code;
          steckbrief.id = result.id;
        },
        error: error => {
          this.isSending = false;
          this.error = true;
          this.readyToUpload = false;
          this.errorMessages = error.error;
        },
        complete: () => {
          if (this.readyToUpload) {
            const documentSaveArray = [];

            steckbrief.documents?.forEach(document => {
              if (document.state === DocumentState.New || document.state === DocumentState.Edited) {
                documentSaveArray.push(this.aduraService.saveSteckbriefDocument(steckbrief, document));
              } else if (document.state === DocumentState.Deleted) {
                documentSaveArray.push(this.aduraService.deleteSteckbriefDocument(steckbrief, document));
              }
            });

            forkJoin(documentSaveArray).subscribe({
              next: () => console.log('Document Saved'),
              error: (error) => {
                this.isSending = false;
                this.error = true;
                this.errorMessages = error.error;
              },
              complete: () => this.routerNavigateToSteckbriefView(steckbrief.code)
            });
          }
        }
      })
      .add(() => {
        this.readyToUpload = true;
      });
  }

  deleteSteckbrief() {
    this.delChosen = true;
    Swal.fire({
      text: this.translateService.instant('adura.steckbrief.delete.title'),
      icon: 'warning',
      customClass: { htmlContainer: 'content-text-center' },
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('adura.steckbrief.delete.confirm'),
      cancelButtonText: this.translateService.instant('global.cancel')
    }).then((result) => {
      if (result.value) {
        this.continueDeleteSteckbrief();
      } else {
        this.delChosen = false;
      }
    });
  }

  private continueDeleteSteckbrief() {
    this.isSending = true;

    this.aduraService.deleteSteckbrief(this.steckbrief.id)
      .subscribe({
        next: () => {
          location.href = '/adura/search';
        },
        error: error => {
          this.isSending = false;
          this.deleteError = true;
          this.errorMessages = error.error;
          console.log('isSending');
          console.log(this.isSending);
          console.log(this.errorMessages);
        }
      });
  }

  routerNavigateToSteckbriefView(code: string) {
    this.showSuccessAlert('adura.steckbrief.saved');
    this.router.navigate(['/adura/factsheet/', code]);
  }

  selectBereich(event) {
    const target = event.target;
    const selectedBereichId = +target.value;
    const selectedBereich = this.bereichList.find(it => it.id === selectedBereichId);

    const bereicheFormArray = this.steckbriefForm.get('bereiche') as UntypedFormArray;
    const formControlIndex = bereicheFormArray.controls.findIndex(it => it.value.id === selectedBereichId);

    // Bereich dem Form hinzufügen und primär = true / false setzen
    if (target.checked && formControlIndex === -1) {
      selectedBereich.primaer = true;
      bereicheFormArray.push(new UntypedFormControl(selectedBereich));
    } else if (!target.checked && formControlIndex > -1) {
      bereicheFormArray.removeAt(formControlIndex);
    }

    this.markFormDirty();
  }

  isBereichActive(bereichId: number): boolean {
    const selectedBereich = this.steckbrief?.bereiche?.find(
      (bereich) => bereich.id === bereichId
    );
    return selectedBereich !== undefined;
  }

  removeMeldung(meldung: Meldung): void {
    const meldungFormArray = this.steckbriefForm.get('meldungen') as UntypedFormArray;
    const meldungArray: Meldung [] = meldungFormArray.value?.filter(meld => meld.id !== meldung.id);
    meldungFormArray.patchValue(meldungArray);
    this.markFormDirty();
  }

  get cancelLink() {
    if (this.steckbrief?.code) {
      return '/adura/factsheet/' + this.steckbrief.code;
    } else {
      // Gehe zur Suchseite
      return '/adura/search/';
    }
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });
        });
  }

  get id() {
    return this.steckbrief?.id || null;
  }

  get titel() {
    return (this.steckbriefForm.get('signal').value ? '<i class="fa fa-flag"></i>' : '<i class="fa fa-file-text-o"></i>') + '&nbsp;&nbsp;' + this.steckbrief.titel;
  }

  get code() {
    return this.steckbrief?.code || null;
  }

  get basisQuelle() {
    return (this.steckbrief && this.steckbriefForm.get('basisQuelle') &&
      this.steckbriefForm.get('basisQuelle').value) ? this.steckbriefForm.get('basisQuelle').value : null;
  }

  get weitereQuellen(): PublikationDetail[] {
    return (this.steckbrief && this.steckbriefForm.get('weitereQuellen') && this.steckbriefForm.get('weitereQuellen').value)
      ? this.steckbriefForm.get('weitereQuellen').value : null;
  }

  get matrix() {
    return this.steckbrief?.matrix || null;
  }

  get bereiche() {
    return this.steckbrief?.bereiche || null;
  }

  get creationDateForm() {
    return this.steckbriefForm.get('creationDate');
  }

  get sortedDocuments() {
    return this.steckbrief?.documents || null;
  }

  getCreationDateFromDatePicker(event: any) {
    this.selectedCreationDate = dayjs(event.target.value.split('.').reverse().join('-'));

  }

  getCreationDate() {
    if (this.selectedCreationDate) {
      this.creationDate = this.selectedCreationDate.format('YYYY-MM-DD');
    }
  }

  get nextEvaluationForm() {
    return this.steckbriefForm.get('nextEvaluation');
  }

  getnextEvaluationFromDatePicker(event: any) {
    this.selectedNextEvaluation = dayjs(event.target.value.split('.').reverse().join('-'));

  }

  getNextEvaluation() {
    if (this.selectedNextEvaluation) {
      this.nextEvaluation = this.selectedNextEvaluation.format('YYYY-MM-DD');
    }
  }

  openAddBeurteilung() {
    this.beurteilungEdit.open(new Beurteilung());
  }

  openEditBeurteilung(beurteilung: Beurteilung) {
    this.beurteilungEdit.open(beurteilung);
  }

  updateBeurteilungList(beurteilung: Beurteilung) {
    const beurteilungenFormArray = this.steckbriefForm.get('beurteilungen') as UntypedFormArray;

    if (!beurteilungenFormArray.value?.includes(beurteilung)) {
      beurteilungenFormArray.value?.push(beurteilung);
      this.markFormDirty();
    }
  }

  confirmRemoveBeurteilung(beurteilung: Beurteilung) {
    Swal.fire({
      text: this.translateService.instant('adura.beurteilung.delete.title'),
      icon: 'warning',
      customClass: { htmlContainer: 'content-text-center' },
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('adura.beurteilung.delete.confirm'),
      cancelButtonText: this.translateService.instant('global.cancel'),
    }).then((result) => {
      if (result.value) {
        this.removeBeurteilung(beurteilung);
      }
    });
  }

  private removeBeurteilung(beurteilung: Beurteilung) {
    const beurteilungenFormArray = this.steckbriefForm.get('beurteilungen') as UntypedFormArray;
    const beurteilungenArray: Beurteilung [] = beurteilungenFormArray.value?.filter(btlg => btlg !== beurteilung);
    beurteilungenFormArray.clear();
    this.markFormDirty();

    if (beurteilungenArray.length === 0) {
      this.steckbriefForm.get('beurteilungen').setValue([]);
    } else {
      this.setFormArrayValues(beurteilungenFormArray, beurteilungenArray);
    }
  }

  openAddAktivitaet() {
    this.aktivitaetEdit.open(new Aktivitaet());
  }

  openEditAktivitaet(aktivitaet: Aktivitaet) {
    console.log('openedit');
    console.log(aktivitaet);
    this.aktivitaetEdit.open(aktivitaet);
  }

  updateAktivitaetList(aktivitaet: Aktivitaet) {
    const aktivitaetFormArray = this.steckbriefForm.get('aktivitaeten') as UntypedFormArray;

    if (!aktivitaetFormArray.value?.includes(aktivitaet)) {
      aktivitaetFormArray.value?.push(aktivitaet);
      this.markFormDirty();
    }
  }

  confirmRemoveAktivitaet(aktivitaet: Aktivitaet) {
    Swal.fire({
      text: this.translateService.instant('adura.aktivitaet.delete.title'),
      icon: 'warning',
      customClass: { htmlContainer: 'content-text-center' },
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('adura.aktivitaet.delete.confirm'),
      cancelButtonText: this.translateService.instant('global.cancel'),
    }).then((result) => {
      if (result.value) {
        this.removeAktivitaet(aktivitaet);
      }
    });
  }

  removeAktivitaet(aktivitaet: Aktivitaet) {
    const aktivitaetFormArray = this.steckbriefForm.get('aktivitaeten') as UntypedFormArray;
    const aktivitaetenArray: Aktivitaet [] = aktivitaetFormArray.value?.filter(akt => akt !== aktivitaet);
    aktivitaetFormArray.clear();
    this.markFormDirty();

    if (aktivitaetenArray.length === 0) {
      this.steckbriefForm.get('aktivitaeten').setValue([]);
    } else {
      this.setFormArrayValues(aktivitaetFormArray, aktivitaetenArray);
    }
  }

  openCreateGefahr() {
    this.gefahrCreate.open();
  }

  reloadGefahrList() {
    this.aduraService.listGefahr()
      .subscribe(
        (result) => {
          this.gefahrList = result;
        });
  }

  removeGefahr(gefahr: Gefahr): void {
    const gefahrenFormArray = this.steckbriefForm.get('gefahren') as UntypedFormArray;
    const gefahrenArray: Gefahr [] = gefahrenFormArray.value?.filter(gef => gef.id !== gefahr.id);
    gefahrenFormArray.patchValue(gefahrenArray);
    this.markFormDirty();
  }

  openAddMatrix() {
    this.matrixEdit.open();
  }

  openEditMatrix(matrix: Matrix) {
    this.matrixEdit.open(matrix);
  }

  updateMatrixList(matrix: Matrix) {
    const matrixFormArray = this.steckbriefForm.get('matrix') as UntypedFormArray;

    if (!matrixFormArray.value?.includes(matrix)) {
      matrixFormArray.value?.push(matrix);
      this.markFormDirty();
    }
  }

  removeMatrix(matrix: Matrix) {
    const matrixFormArray = this.steckbriefForm.get('matrix') as UntypedFormArray;
    const matrixArray: Matrix [] = matrixFormArray.value?.filter(mtx => mtx !== matrix);
    matrixFormArray.clear();
    this.markFormDirty();

    if (matrixArray.length === 0) {
      this.steckbriefForm.get('matrix').setValue([]);
    } else {
      this.setFormArrayValues(matrixFormArray, matrixArray);
    }
  }

  markFormDirty() {
    this.steckbriefForm.markAsDirty();
  }

  preventEnterInTinyMceEditor(editor) {
    editor.on('keydown', e => (e.keyCode !== 13));
  }

  disableInputsByRole() {
    if (!this.authenticationService.hasRole('AWISA_FRESIL')) {
      this.disableInputByRole = true;
    }
  }

  redirectByStatus() {
    if (!this.authenticationService.hasRole('AWISA_FRESIL')) {

      if (this.steckbrief.status?.id === 70) {
        this.router.navigate(['/adura/factsheet/', this.steckbrief.code]);
      }
    }

    if (this.steckbrief.status?.id === 90) {
      this.router.navigate(['/adura/factsheet/', this.steckbrief.code]);
    }
  }

  hasError(field: string) {
    if (Array.isArray(this.errorMessages)) {
      return this.errorMessages.find(it => it.path === field);
    } else {
      return false;
    }
  }

  getError(field: string) {
    if (Array.isArray(this.errorMessages)) {
      const error = this.errorMessages.find(it => it.path === field);
      return error.message;
    }
    return '';
  }

  updateRemainingCharacters(fieldName: string, event: any) {
    const editor = event.editor;
    const maxChars = this.maxCharsMap[fieldName];
    let editorText = this.steckbriefForm.get(fieldName).value;

    if (maxChars < editorText?.length) {
      editorText = editorText.slice(0, maxChars);
      this.steckbriefForm.get(fieldName).setValue(editorText);
      // https://stackoverflow.com/a/19836226
      editor.selection.select(editor.getBody(), true);
      editor.selection.collapse(false);
    }

    this.remainingCharactersMap[fieldName] = maxChars - (editorText?.length || 0);
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return !this.steckbriefForm.dirty;
  }

  dropzoneFileUploading([file, _xhr, formData]): void {
    const nameWithoutExtension = file.name?.replace(/\.[^.]+$/, '');
    formData.append('name', nameWithoutExtension);
    formData.append('autorisierung', 20);
    this.markFormDirty();
  }

  dropzoneFileAdded([file, result]): void {
    this.zone.runOutsideAngular(() => {
      const dz = this.dropzone.directiveRef.dropzone();
      dz.removeFile(file);
    });

    const doc = new AduraDocument();
    doc.state = DocumentState.Temporary;
    doc.autorisierung = result.autorisierung;
    doc.id = result.id;
    doc.name = result.name;
    doc.extension = result.extension;
    doc.file = file;
    this.steckbrief.documents.push(doc);
    this.markFormDirty();
  }

  dropzoneFileError([file, error]): void {
    this.dropzoneError.push(error);
    this.zone.runOutsideAngular(() => {
      const dz = this.dropzone.directiveRef.dropzone();
      dz.removeFile(file);
    });
  }
}
