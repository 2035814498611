<header class="page-header front-header">
  <div class="container admin">
    <div class="row">
      <div class="col-md-6">
        <h1>{{ 'admin.label' | translate }} adura</h1>
      </div>
      <div class="col-md-5">
        <div class="col-md-12"><b>App-Versionen</b></div>
        <div class="col-md-5">Frontend:</div>
        <div class="col-md-7">{{ getFrontendVersion }}</div>
        <div class="col-md-5">adura:</div>
        <div class="col-md-7">{{ getAduraVersion }}</div>
      </div>
    </div>
  </div>
</header>

<div class="admin-content adura-admin-content" role="main">
  <section id="explore" class="container">
    <app-tabs (tabChanged)="onCategoryChange($event)" [selectedId]="currentCategory">
      <ng-template [appHasRole]="['ADMIN_AWISA_ADMIN']">
        <app-tab tabId="log" tabTitle="{{ 'adura.admin.tabs.html.log' | translate }}" plainTabTitle="{{ 'adura.admin.tabs.plain.log' | translate }}" contentClass="tabmenu-content">
          <div class="row admin">
            <div class="col-md-6 log-period"><p class="admin log-period">Log-Einträge als CSV anzeigen</p>
              <select class="log-period" id="select-log-period" name="sort" [(ngModel)]="selectedPeriod">
                <option selected value="1">24h</option>
                <option value="7">Letzte Woche</option>
                <option value="30">Letzter Monat</option>
                <option value="365">Letztes Jahr</option>
                <option value="0">gesamt</option>
              </select>
            </div>
            <div class="col-md-3">
              <button (click)="getLogFile()" class="btn btn-sm btn-default">Job-Logs herunterladen</button>
            </div>
          </div>
          <div class="row admin">
            <div class="col-md-6"><p class="admin">Lucene-Index neu generieren</p></div>
            <div class="col-md-5">
              <button [disabled]="createIndex" (click)="createLuceneIndex()" class="btn btn-sm btn-default">
                <i class="fa fa-play fa-fw" [ngClass]="createIndex?'fa-spinner fa-pulse':'fa-play'"></i>
              </button>
            </div>
          </div>
        </app-tab>
      </ng-template>
      <ng-template [appHasRole]="['AWISA_FRESIL']">
        <app-tab tabId="report" tabTitle="{{ 'adura.admin.tabs.html.reports' | translate }}" plainTabTitle="{{ 'adura.admin.tabs.plain.reports' | translate }}" contentClass="tabmenu-content">
          <div class="row admin tabmenu-content-report">
            <div class="col-sm-12">
              <app-adura-report-kennzahlen></app-adura-report-kennzahlen>
            </div>
          </div>
        </app-tab>
      </ng-template>
      <ng-template [appHasRole]="['AWISA_FRESIL']">
        <app-tab tabId="freigabe" tabTitle="{{ 'adura.admin.tabs.html.freigabe' | translate }}" plainTabTitle="{{ 'adura.admin.tabs.plain.freigabe' | translate }}" contentClass="tabmenu-content">
          <app-adura-massenfreigabe></app-adura-massenfreigabe>
        </app-tab>
      </ng-template>
      <ng-template [appHasRole]="['ADMIN_AWISA_ADMIN']">
        <app-tab tabId="papierkorb" tabTitle="{{ 'adura.admin.tabs.html.papierkorb' | translate }}" plainTabTitle="{{ 'adura.admin.tabs.plain.papierkorb' | translate }}" contentClass="tabmenu-content">
          <app-adura-admin-papierkorb></app-adura-admin-papierkorb>
        </app-tab>
      </ng-template>
      <ng-template [appHasRole]="['AWISA_FRESIL']">
        <app-tab tabId="danger" tabTitle="{{ 'adura.admin.tabs.html.gefahr' | translate }}" plainTabTitle="{{ 'adura.admin.tabs.plain.gefahr' | translate }}" contentClass="tabmenu-content">
          <app-adura-admin-stammdaten-gefahr></app-adura-admin-stammdaten-gefahr>
        </app-tab>
      </ng-template>
      <ng-template [appHasRole]="['AWISA_FRESIL']">
        <app-tab tabId="driver" tabTitle="{{ 'adura.admin.tabs.html.treiber' | translate }}" plainTabTitle="{{ 'adura.admin.tabs.plain.treiber' | translate }}" contentClass="tabmenu-content">
          <app-adura-admin-stammdaten-treiber></app-adura-admin-stammdaten-treiber>
        </app-tab>
      </ng-template>
      <ng-template [appHasRole]="['AWISA_FRESIL']">
        <app-tab tabId="publication" tabTitle="{{ 'adura.admin.tabs.html.publications' | translate }}" plainTabTitle="{{ 'adura.admin.tabs.plain.publications' | translate }}" contentClass="tabmenu-content">
          <app-adura-admin-stammdaten-publikation></app-adura-admin-stammdaten-publikation>
        </app-tab>
      </ng-template>
    </app-tabs>
  </section>
</div>
