import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpStatusCode } from '@angular/common/http';
import { AduraService } from '@app/shared/services/adura.service';
import { TranslateService } from '@ngx-translate/core';
import { Publikation, PublikationAdmin, PublikationDetail } from '@app/shared/classes/adura/publikation';
import { StringDateValidator } from '@app/shared/validators/string-date.validator';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adura-publikation-add',
  templateUrl: './adura-publikation-add.component.html'
})
export class AduraPublikationAddComponent implements OnInit, AfterViewInit {
  @ViewChild('publikationAddModal', { static: false }) publikationAddModal: ElementRef;
  @Output() updateAddBasisQuelle = new EventEmitter<PublikationDetail>();
  @Output() updateAddOriginalPublikation = new EventEmitter<PublikationDetail>();
  private elm: HTMLElement;
  publikationAddForm: UntypedFormGroup;
  publikationCreateForm: UntypedFormGroup;

  publikationList: Publikation[];

  pubDetail: PublikationDetail;
  pubDetailType: number;

  isSending = false;
  error = false;
  errorMessage: string;
  showPublikationCreate = false;
  readyToInitForm = false;

  constructor(private aduraService: AduraService,
              private translateService: TranslateService,
              private router: Router,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.refreshPublikationList();
  }

  ngAfterViewInit(): void {
    this.elm = this.publikationAddModal.nativeElement as HTMLElement;
  }

  private initBasisQuelleForm() {
    this.publikationAddForm = this.formBuilder.group({
      publikation: [[], Validators.required],
      link: ['', Validators.required],
      pageCount: [null, Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
      date: ['', Validators.compose([Validators.required, StringDateValidator.date])]
    });
    this.initCreateForm();
  }

  private initOriginalPublikationForm() {
    this.publikationAddForm = this.formBuilder.group({
      publikation: [[], Validators.required],
      link: ['', Validators.required]
    });
    this.initCreateForm();
  }

  private updateForm() {
    this.publikationAddForm.reset({
      publikation: this.pubDetail.publikation,
      link: this.pubDetail.link,
      pageCount: this.pubDetail.pageCount,
      date: this.pubDetail.date
    });
  }

  onSubmit() {
    this.isSending = true;
    const formValues = this.publikationAddForm.value;
    this.pubDetail.link = formValues.link;
    this.pubDetail.date = formValues.date;
    this.pubDetail.type = this.pubDetailType;
    this.pubDetail.pageCount = formValues.pageCount;
    this.pubDetail.publikation = this.publikationAddForm.get('publikation').value;

    if (this.pubDetailType === 10) {
      this.updateAddBasisQuelle.emit(this.pubDetail);
    } else {
      this.updateAddOriginalPublikation.emit(this.pubDetail);
    }

    this.isSending = false;
    this.close();
  }

  close() {
    this.publikationAddForm.reset({}, { emitEvent: false });
    this.elm.classList.remove('show');
    setTimeout(() => {
      this.elm.style.width = '0';
    }, 75);
    this.error = false;
  }

  open(publikationDetail: PublikationDetail, pubDetailType: number): void {
    this.elm.classList.add('show');
    this.elm.style.width = '100vw';
    this.pubDetailType = pubDetailType;
    this.readyToInitForm = true;

    if (pubDetailType === 10) {
      this.initBasisQuelleForm();
    } else {
      this.initOriginalPublikationForm();
    }

    if (publikationDetail) {
      this.pubDetail = publikationDetail;
      this.updateForm();
    }
  }

  compareFn(item, selected) {
    return item.id === selected.id;
  }

  refreshPublikationList() {
    this.aduraService.listPublikation()
      .subscribe(
        (result) => {
          this.publikationList = result;
        });
  }

  openPublikationCreate() {
    this.showPublikationCreate = true;
  }

  closePublikationCreate() {
    this.showPublikationCreate = false;
  }

  private initCreateForm() {
    this.publikationCreateForm = this.formBuilder.group({
      titel: ['', Validators.required],
      abkuerzung: ['', Validators.required]
    });
  }

  savePublikation() {
    this.isSending = true;
    const formValues = this.publikationCreateForm.value;
    const daten = new PublikationAdmin();
    daten.titel = formValues.titel;
    daten.abkuerzung = formValues.abkuerzung;
    daten.deaktiviert = false;
    console.log(daten);

    this.aduraService.createPublikation(daten)
      .subscribe({
        next: () => {
          this.refreshPublikationList();
          this.showSuccessAlert('adura.publication.stored');
          this.showPublikationCreate = false;
          this.initCreateForm();
          this.isSending = false;
        },
        error: (error) => {
          if (error.status === HttpStatusCode.BadRequest) {
            this.showAlert(error.error);
          } else {
            this.showAlert(null);
          }
          console.error(error);
          this.isSending = false;
        }
      });
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
          });
        });
  }

  showAlert(errors: any[]) {
    let errorString = '';
    if (errors === null) {
      errorString = 'Die Publikation konnte nicht gespeichert werden!';
    } else {
      errors.forEach(entry => {
        errorString += entry.message + '<br/>';
      });
    }
    Swal.fire({
      html: errorString,
      icon: 'error',
      customClass: { htmlContainer: 'content-text-center' },
      buttonsStyling: true,
      confirmButtonText: 'OK'
    });
  }

  hasFieldError(field: string): boolean{
    const formErrors = this.publikationAddForm.controls[field].errors;
    return this.publikationAddForm.controls[field].dirty && formErrors && formErrors[field];
  }
}
