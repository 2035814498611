import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AduraService } from '@app/shared/services/adura.service';
import { TranslateService } from '@ngx-translate/core';
import { Aktivitaet } from '@app/shared/classes/adura/aktivitaet';
import { Steckbrief } from '@app/shared/classes/adura/steckbrief';
import Swal from 'sweetalert2';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-adura-aktivitaet-edit',
  templateUrl: './adura-aktivitaet-edit.component.html'
})
export class AduraAktivitaetEditComponent implements OnInit, AfterViewInit {
  @ViewChild('aktivitaetEditModal', {static: false}) aktivitaetEditModal: ElementRef;
  @Output() updateAddAktivitaet = new EventEmitter<Aktivitaet>();
  @Input() steckbrief: Steckbrief;

  private elm: HTMLElement;
  aktivitaetEditForm: UntypedFormGroup;

  statusList = [10, 15, 20, 90];
  aktivitaetToEdit: Aktivitaet;

  selectedDate: dayjs.Dayjs;
  date: string;
  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: 'de'
  };

  tinyConfigEnterAndLinksAllowed: any;

  remainingCharactersMap = {
    text: ''
  };

  maxCharsMap = {
    text: 500
  };

  constructor(private aduraService: AduraService,
              private translateService: TranslateService,
              private router: Router,
              private formBuilder: UntypedFormBuilder) {
    this.initForm();
  }

  ngOnInit(): void {
    this.datePickerConfig.locale = this.translateService.currentLang;
    this.setupTinyMce();
  }

  ngAfterViewInit(): void {
    this.elm = this.aktivitaetEditModal.nativeElement as HTMLElement;
  }

  private setupTinyMce(): void {
    this.tinyConfigEnterAndLinksAllowed = {
      menubar: false,
      plugins: 'link',
      toolbar: 'undo link',
      valid_elements: 'a[href|target=_blank]',
      default_link_target: '_blank',
      target_list: false,
      entity_encoding: 'raw',
      convert_urls: false
    };
  }

  private initForm() {
    this.aktivitaetEditForm = this.formBuilder.group({
      who: [''],
      till: [''],
      text: ['', Validators.required],
      status: ['', Validators.required]
    });
  }

  private updateForm() {
    const aktivitaetDate = this.aktivitaetToEdit.till ? dayjs(this.aktivitaetToEdit.till) : null;
    this.aktivitaetEditForm.reset({
      who: this.aktivitaetToEdit.who,
      till: aktivitaetDate,
      text: this.aktivitaetToEdit.text,
      status: this.aktivitaetToEdit.status === undefined ? this.statusList[0] : this.aktivitaetToEdit.status
    });
  }

  onSave() {
    const formValues = this.aktivitaetEditForm.value;
    this.aktivitaetToEdit.who = formValues.who;
    this.aktivitaetToEdit.till = formValues.till;
    this.aktivitaetToEdit.text = formValues.text;
    this.aktivitaetToEdit.status = formValues.status;
    this.updateAddAktivitaet.emit(this.aktivitaetToEdit);
    this.close();
  }

  close() {
    this.aktivitaetEditForm.reset({}, { emitEvent: false });
    this.elm.classList.remove('show');
    setTimeout(() => {
      this.elm.style.width = '0';
    }, 75);
  }

  open(aktivitaet: Aktivitaet): void {
    this.elm.classList.add('show');
    this.elm.style.width = '100vw';
    this.aktivitaetToEdit = aktivitaet;
    this.updateForm();
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
          });
        });
  }

  getDate() {
    if (this.selectedDate) {
      this.date = this.selectedDate.format('YYYY-MM-DD');
    }
  }

  get dateForm() {
    return this.aktivitaetEditForm.get('till');
  }

  updateRemainingCharacters(fieldName: string, event: any) {
    const editor = event.editor;
    const maxChars = this.maxCharsMap[fieldName];
    let editorText = this.aktivitaetEditForm.get(fieldName).value;

    if (maxChars < editorText?.length) {
      editorText = editorText.slice(0, maxChars);
      this.aktivitaetEditForm.get(fieldName).setValue(editorText);
      // https://stackoverflow.com/a/19836226
      editor.selection.select(editor.getBody(), true);
      editor.selection.collapse(false);
    }

    this.remainingCharactersMap[fieldName] = maxChars - (editorText?.length || 0);
  }
}
