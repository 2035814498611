import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { User } from '@app/core/auth/user';
import { SettingsService } from '@app/core/config/settings.service';
import { PersonService } from '@app/shared/services/person.service';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subject, SubscriptionLike } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigurationService } from '@app/core/config/configuration.service';
import Swal from 'sweetalert2';

declare let require: any;
const clientDescription = require('../../../package.json').description;
const clientVersion = require('../../../package.json').version;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject();

  currentUser: User;
  cssClass = '';
  versionInfo = '';
  serverVersion = '';
  envNote = '';
  menuSelected = '';
  backendPingTask: SubscriptionLike;


  currentLanguage: string;
  availableLanguages = [];
  contentTypes = ['document', 'event', 'link'];
  urlLogo = '../../assets/images/logo.svg';

  constructor(private settingsService: SettingsService,
              private authenticationService: AuthenticationService,
              private configurationService: ConfigurationService,
              private personService: PersonService,
              private translate: TranslateService,
              private router: Router) {
  }

  @Output() versionLabel: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    this.versionLabel.emit(this.versionInfo);

    this.authenticationService.authenticated$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((authenticated) => {
        this.currentUser = this.authenticationService.getUser();
      });

    this.currentLanguage = this.translate.currentLang;
    this.translate.onLangChange
      .pipe(takeUntil(this.destroyed$))
      .subscribe(event => {
        this.currentLanguage = event.lang;
      });

    this.authenticationService.onTokenExpired = () => this.tokenExpired();
    this.backendPingTask = interval(10_000).subscribe(() => {
      this.personService.checkLoginStatus().subscribe(
        (loggedIn: boolean) => loggedIn || this.tokenExpired()
      );
    });

    switch (this.configurationService.getStage()) {
      case 'EN':
        this.urlLogo = '../../assets/images/logo_en.svg';
        break;
      case 'IN':
        this.urlLogo = '../../assets/images/logo_in.svg';
        break;
      case 'VP':
        this.urlLogo = '../../assets/images/logo_vp.svg';
        break;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.backendPingTask?.unsubscribe();
  }

  tokenExpired() {
    this.backendPingTask?.unsubscribe();
    Swal.fire({
      icon: 'info',
      title: this.translate.instant('global.tokenExpired.title'),
      text: this.translate.instant('global.tokenExpired.text'),
      confirmButtonText: this.translate.instant('global.tokenExpired.reload'),
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    }).then(() => location.reload());
  }

  getContentTypes() {
    // Die inaktiven Elemente werden aus der Liste entfernt
    return this.contentTypes.filter(el => this.isActive(el));
  }

  isActive(value) {
    const set = this.settingsService.settings().find(el => el.key === 'gui.add.' + value + '.enabled');
    if (set.value === '1') {
      return value;
    }
  }

  addContent(contentType: string) {
    this.menuSelected = '';
    this.router.navigate([contentType, 'new']);
  }

  getLanguages() {
    this.availableLanguages = [];
    for (const lang of this.translate.getLangs()) {
      if (lang !== this.translate.currentLang) {
        this.availableLanguages.push(lang);
      }
    }
    return this.availableLanguages;
  }

  changeLanguage(newLang: string) {
    const user = this.currentUser;
    user.language = newLang;
    this.menuSelected = '';
    this.personService.changeLanguage(user)
      .subscribe(result => {
        console.log(result);
        this.currentUser.language = result.language;
        this.translate.use(this.currentUser.language);
        window.location.reload();
      });
  }

  isMenuSelected(menuName: string) {
    return menuName === this.menuSelected;
  }

  setMenuSelection(e) {
    this.menuSelected = e.target.ariaLabel;
  }

  userProfile() {
    this.menuSelected = '';
    this.authenticationService.goToMyAccount();
  }

  logout() {
    this.menuSelected = '';
    this.authenticationService.logout();
  }

  awisaActive(): boolean {
    return !this.router.url.includes('/adura/');
  }

  showLink(): boolean {
    return !(this.awisaActive() && this.authenticationService.onlyAwisaUser()
              || !this.awisaActive() && this.authenticationService.onlyAduraUser());
  }

  showAdminIcon(): boolean {
    // Der Benutzer braucht entweder die Admin-Rolle oder die Fresil-Rolle, damit er Einstellungen machen kann
    return (this.authenticationService.hasRole('ADMIN_AWISA_ADMIN') || (this.authenticationService.hasRole('AWISA_FRESIL')));
  }
}
