import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';

export interface CreatedDateChangedEvent {
  createdDateFrom: string;
  createdDateTo: string;
}

export interface ChangedDateChangedEvent {
  changedDateFrom: string;
  changedDateTo: string;
}

@Component({
  selector: 'app-adura-massenfreigabe-date-filter',
  templateUrl: './adura-massenfreigabe-date-filter.component.html'
})
export class AduraMassenfreigabeDateFilterComponent implements OnInit, OnChanges {
  @Input() activeCreatedDateFrom = '';
  @Input() activeCreatedDateTo = '';
  @Output() createdDateChanged = new EventEmitter<CreatedDateChangedEvent>();

  @Input() activeChangedDateFrom = '';
  @Input() activeChangedDateTo = '';
  @Output() changedDateChanged = new EventEmitter<ChangedDateChangedEvent>();

  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: 'de'
  };

  selectedCreatedDateFrom: any;
  selectedCreatedDateTo: any;
  selectedChangedDateFrom: any;
  selectedChangedDateTo: any;

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
    this.datePickerConfig.locale = this.translateService.currentLang;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // CreatedDate
    if (this.activeCreatedDateFrom) {
      this.selectedCreatedDateFrom = dayjs(this.activeCreatedDateFrom);
    } else {
      this.activeCreatedDateFrom = '';
      delete this.selectedCreatedDateFrom;
    }

    if (this.activeCreatedDateTo) {
      this.selectedCreatedDateTo = dayjs(this.activeCreatedDateTo);
    } else {
      this.activeCreatedDateTo = '';
      delete this.selectedCreatedDateTo;
    }

    // ChangedDate
    if (this.activeChangedDateFrom) {
      this.selectedChangedDateFrom = dayjs(this.activeChangedDateFrom);
    } else {
      this.activeChangedDateFrom = '';
      delete this.selectedChangedDateFrom;
    }

    if (this.activeChangedDateTo) {
      this.selectedChangedDateTo = dayjs(this.activeChangedDateTo);
    } else {
      this.activeChangedDateTo = '';
      delete this.selectedChangedDateTo;
    }
  }

  getCreatedDateFromEvent(event: any) {
    this.selectedCreatedDateFrom = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getCreatedDateFrom();
  }

  getCreatedDateFrom() {
    if (this.selectedCreatedDateFrom) {
      this.activeCreatedDateFrom = this.selectedCreatedDateFrom.format('YYYY-MM-DD');
      this.getCreatedDateData();
    }
  }

  getCreatedDateToEvent(event: any) {
    this.selectedCreatedDateTo = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getCreatedDateTo();
  }

  getCreatedDateTo() {
    if (this.selectedCreatedDateTo) {
      this.activeCreatedDateTo = this.selectedCreatedDateTo.format('YYYY-MM-DD');
      this.getCreatedDateData();
    }
  }

  getCreatedDateData() {
    let params;

    if (this.selectedCreatedDateFrom === undefined && this.selectedCreatedDateTo === undefined) {
      params = { createdDateFrom: undefined, createdDateTo: undefined };
    } else if (this.selectedCreatedDateFrom === undefined) {
      params = { createdDateFrom: undefined, createdDateTo: this.activeCreatedDateTo };
    } else if (this.selectedCreatedDateTo === undefined) {
      params = { createdDateFrom: this.activeCreatedDateFrom, createdDateTo: undefined };
    } else {
      params = { createdDateFrom: this.activeCreatedDateFrom, createdDateTo: this.activeCreatedDateTo };
    }

    this.createdDateChanged.emit(params);
  }

  updateEmptyCreatedDateData() {
    this.getCreatedDateData();
  }

  getChangedDateFromEvent(event: any) {
    this.selectedChangedDateFrom = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getChangedDateFrom();
  }

  getChangedDateFrom() {
    if (this.selectedChangedDateFrom) {
      this.activeChangedDateFrom = this.selectedChangedDateFrom.format('YYYY-MM-DD');
      this.getChangedDateData();
    }
  }

  getChangedDateToEvent(event: any) {
    this.selectedChangedDateTo = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getChangedDateTo();
  }

  getChangedDateTo() {
    if (this.selectedChangedDateTo) {
      this.activeChangedDateTo = this.selectedChangedDateTo.format('YYYY-MM-DD');
      this.getChangedDateData();
    }
  }

  getChangedDateData() {
    let params;

    if (this.selectedChangedDateFrom === undefined && this.selectedChangedDateTo === undefined) {
      params = { changedDateFrom: undefined, changedDateTo: undefined };
    } else if (this.selectedChangedDateFrom === undefined) {
      params = { changedDateFrom: undefined, changedDateTo: this.activeChangedDateTo };
    } else if (this.selectedChangedDateTo === undefined) {
      params = { changedDateFrom: this.activeChangedDateFrom, changedDateTo: undefined };
    } else {
      params = { changedDateFrom: this.activeChangedDateFrom, changedDateTo: this.activeChangedDateTo };
    }

    this.changedDateChanged.emit(params);
  }

  updateEmptyChangedDateData() {
    this.getChangedDateData();
  }
}
