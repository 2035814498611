import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Task } from '@app/shared/classes/task';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { DataExchangeService } from '@app/shared/services/data-exchange.service';
import { MailerService } from '@app/shared/services/mailer.service';
import { AduraService } from '@app/shared/services/adura.service';
import { TabChangeEvent } from '@app/shared/tabs/tabs.component';
import Swal from 'sweetalert2';

declare let require: any;
const clientVersion = require('../../../package.json').version;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {

  runningAllJobs = false;
  runAllTask: Task = new Task();
  loadingStructure = false;
  makeStructureTask: Task = new Task();
  loadingDocuments = false;
  fetchDocumentsTask: Task = new Task();
  loadingFetchChanges = false;
  listAwisaChangesTask: Task = new Task();
  loadingFetchTranslations = false;
  fetchTranslationsTask: Task = new Task();
  loadingFetchProcessThemaWordLinks = false;
  processThemaWordLinksTask: Task = new Task();
  loadingUpdateUsers = false;
  updateUsersTask: Task = new Task();
  loadingProcessSubscriptions = false;
  processSubscriptionsTask: Task = new Task();
  createIndex = false;
  createIndexTask: Task = new Task();
  loadingTruncateDB = false;
  procTruncateDatabase = '';
  docsInDbResult = '';
  testmailResult = '';

  awisaFoVersion: string;
  dataExchangeVersion: string;
  mailerVersion: string;
  aduraVersion: string;

  selectedPeriod: any = 1;
  selectedContentPeriod: any = 1;
  currentCategory: string;

  truncatePerson = false;
  @ViewChild('truncateModal') truncateModal: ElementRef;

  constructor(private dataExchangeService: DataExchangeService,
              private awisaFoService: AwisaFoService,
              private mailerService: MailerService,
              private aduraService: AduraService,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.dataExchangeService.lastSuccessOfAllJobs()
      .subscribe(result => {
        result.forEach(job => {
          this[this.getVariableName(job.discriminator)] = job;
        });
      });

    this.mailerService.lastSuccessOfAllJobs()
      .subscribe(result => {
        result.forEach(job => {
          this[this.getVariableName(job.discriminator)] = job;
        });
      });

    this.awisaFoService.getVersion()
      .subscribe(result => {
        if (/^\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(result)) {
          this.awisaFoVersion = result;
        } else {
          this.awisaFoVersion = 'N/A';
        }
      });

    this.dataExchangeService.getVersion()
      .subscribe(result => {
        if (/^\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(result)) {
          this.dataExchangeVersion = result;
        } else {
          this.dataExchangeVersion = 'N/A';
        }
      });

    this.mailerService.getVersion()
      .subscribe(result => {
        if (/^\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(result)) {
          this.mailerVersion = result;
        } else {
          this.mailerVersion = 'N/A';
        }
      });

    this.aduraService.getVersion()
      .subscribe(result => {
        if (/^\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(result)) {
          this.aduraVersion = result;
        } else {
          this.aduraVersion = 'N/A';
        }
      });

    this.route.queryParams.subscribe( () => {
      this.currentCategory = this.route.snapshot.params.category || 'job';
    });
  }

  runAllJobs() {
    this.setJobStatus(true);
    this.dataExchangeService.runAllJobs()
      .subscribe({
        next: result => {
          result.forEach(job => {
            this[this.getVariableName(job.discriminator)] = job;
          });
          this.setJobStatus(false);
        },
        error: error => {
          console.log(error);
          this.setJobStatus(false);
        }
      });
  }

  getStructure() {
    this.loadingStructure = true;
    this.dataExchangeService.makeStructure()
      .subscribe({
        next: result => {
          this.makeStructureTask = result;
          this.loadingStructure = false;
        },
        error: error => {
          console.log(error);
          this.loadingStructure = false;
        }
      });
  }

  getDocuments() {
    this.loadingDocuments = true;
    this.dataExchangeService.fetchDocuments()
      .subscribe({
        next: result => {
          this.fetchDocumentsTask = result;
          this.loadingDocuments = false;
        },
        error: () => this.loadingDocuments = false
      });
  }

  fetchAwisaChanges() {
    this.loadingFetchChanges = true;
    this.dataExchangeService.fetchAwisaChanges()
      .subscribe({
        next: result => {
          this.listAwisaChangesTask = result;
          this.loadingFetchChanges = false;
        },
        error: error => {
          console.log(error);
          this.loadingFetchChanges = false;
        }
      });
  }

  fetchTranslations() {
    this.loadingFetchTranslations = true;
    this.dataExchangeService.fetchTranslations()
      .subscribe({
        next: result => {
          this.fetchTranslationsTask = result;
          this.loadingFetchTranslations = false;
        },
        error: error => {
          console.log(error);
          this.loadingFetchTranslations = false;
        }
      });
  }

  fetchProcessThemaWordLinks() {
    this.loadingFetchProcessThemaWordLinks = true;
    this.dataExchangeService.fetchProcessThemaWordLinks()
      .subscribe({
        next: result => {
          this.processThemaWordLinksTask = result;
          this.loadingFetchProcessThemaWordLinks = false;
        },
        error: error => {
          console.log(error);
          this.loadingFetchProcessThemaWordLinks = false;
        }
      });
  }

  updateEiamUsers() {
    this.loadingUpdateUsers = true;
    this.dataExchangeService.updateEiamUsers()
      .subscribe({
        next: result => {
          this.updateUsersTask = result;
          this.loadingUpdateUsers = false;
        },
        error: error => {
          console.log(error);
          this.loadingUpdateUsers = false;
        }
      });
  }

  truncateDatabase() {
    this.loadingTruncateDB = true;
    this.truncatePerson = false;
    Swal.fire({
      title: 'Dieser Vorgang löscht ALLE Daten aus der Datenbank und kann nicht rückgängig gemacht werden!\n\nBitte bestätigen...',
      html: this.truncateModal.nativeElement,
      position: 'center',
      icon: 'warning',
      confirmButtonText: 'OK',
      showCancelButton: true,
      cancelButtonText: 'Abbrechen'
    }).then((dialog) => {
      if (dialog.isConfirmed) {
        this.dataExchangeService.truncateDatabase(this.truncatePerson)
          .subscribe({
            next: result => {
              this.loadingTruncateDB = false;
              this.procTruncateDatabase = result;
              this.showSuccessAlert();
            },
            error: error => {
              console.log(error);
              this.loadingTruncateDB = false;
              this.showErrorAlert();
            }
          });
      } else {
        this.loadingTruncateDB = false;
      }
    });
  }

  docsInDb() {
    this.awisaFoService.docsInDb()
      .subscribe({
        next:  result => this.docsInDbResult = result,
        error: error  => console.log(error)
      });
  }

  testmail() {
    this.mailerService.testmail()
      .subscribe({
        next:  result => this.testmailResult = result,
        error: error  => console.log(error)
      });
  }

  processSubscriptions() {
    this.loadingProcessSubscriptions = true;
    this.mailerService.processSubscriptions()
      .subscribe({
        next: result => {
          this.processSubscriptionsTask = result;
          this.loadingProcessSubscriptions = false;
        },
        error: (error) => {
          console.log(error);
          this.loadingProcessSubscriptions = false;
        }
      });
  }

  // Hier wird der erste Buchstabe klein gesetzt
  getVariableName(name: string) {
    return name[0].toLowerCase() + name.slice(1);
  }

  createLuceneIndex() {
    this.createIndex = true;
    this.awisaFoService.createLuceneIndex()
      .subscribe({
        next: () => this.createIndex = false,
        error: (error) => {
          console.log(error);
          this.createIndex = false;
        }
      });
  }

  getLogFile() {
    const days = this.selectedPeriod;
    this.awisaFoService.subscribeToDownload(this.awisaFoService.getLog(days));
  }

  getContentLogFile() {
    const days = this.selectedContentPeriod;
    this.awisaFoService.subscribeToDownload(this.awisaFoService.getContentLog(days));
  }

  getUnclassifiedDocuments() {
    this.awisaFoService.subscribeToDownload(this.awisaFoService.getUnclassifiedDocuments());
  }

  getDocumentsForEvaluation() {
    this.awisaFoService.subscribeToDownload(this.awisaFoService.getDocumentsForEvaluation());
  }

  onCategoryChange(event: TabChangeEvent) {
    this.router.navigate(['/admin', event.tabId], { queryParamsHandling: 'preserve' });
  }

  showSuccessAlert() {
    Swal.fire({
      title: 'Die Datenbank wurde erfolgreich gelöscht!',
      position: 'center',
      icon: 'success',
      showConfirmButton: false,
      timer: 2000
    });
  }

  showErrorAlert() {
    Swal.fire({
      title: 'Verarbeitung fehlgeschlagen',
      position: 'center',
      icon: 'error',
      showConfirmButton: false,
      timer: 2000
    });
  }

  setJobStatus(status: boolean) {
    this.runningAllJobs = status;
    this.loadingFetchChanges = status;
    this.loadingStructure = status;
    this.loadingDocuments = status;
    this.loadingFetchTranslations = status;
    this.loadingFetchProcessThemaWordLinks = status;
    this.createIndex = status;
  }

  get getFrontendVersion() { return clientVersion; }
  get getAwisaFOVersion() { return (this.awisaFoVersion) ? this.awisaFoVersion : '-'; }
  get getDataExchangeVersion() { return (this.dataExchangeVersion) ? this.dataExchangeVersion : '-'; }
  get getMailerVersion() { return (this.mailerVersion) ? this.mailerVersion : '-'; }
  get getAduraVersion() { return (this.aduraVersion) ? this.aduraVersion : '-'; }
}
