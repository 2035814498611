import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';

export interface ModDateChangedEvent {
  changedDateFrom: string;
  changedDateTo: string;
}

@Component({
  selector: 'app-mod-date-side',
  templateUrl: './mod-date-side.component.html'
})
export class ModDateSideComponent implements OnChanges {

  @Input() activeModDateFrom = '';
  @Input() activeModDateTo = '';
  @Output() modDateChanged = new EventEmitter<ModDateChangedEvent>();


  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: ''
  };
  selectedModDateFrom: any;
  selectedModDateTo: any;
  activeModDateCollapseTablet = false;
  currentAwisaLanguage: string;

  constructor(private translateService: TranslateService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.activeModDateFrom) {
      this.selectedModDateFrom = dayjs(this.activeModDateFrom);
    } else {
      this.activeModDateFrom = '';
      delete this.selectedModDateFrom;
    }

    if (this.activeModDateTo) {
      this.selectedModDateTo = dayjs(this.activeModDateTo);
    } else {
      this.activeModDateTo = '';
      delete this.selectedModDateTo;
    }

    this.currentAwisaLanguage = this.translateService.currentLang;
    this.changeDisplayLanguageCalendar();
  }

  changeDisplayLanguageCalendar() {
    this.datePickerConfig.locale = dayjs.locale(this.currentAwisaLanguage);
  }

  getModDateFromFromEvent(event: any) {
    this.selectedModDateFrom = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getModDateFrom();
  }

  getModDateFrom() {
    if (this.selectedModDateFrom) {
      this.activeModDateFrom = this.selectedModDateFrom.format('YYYY-MM-DD');
      this.getDateData();
    }
  }

  getModDateToFromEvent(event: any) {
    this.selectedModDateTo = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getModDateTo();
  }

  getModDateTo() {
    if (this.selectedModDateTo) {
      this.activeModDateTo = this.selectedModDateTo.format('YYYY-MM-DD');
      this.getDateData();
    }
  }

  getDateData() {
    let params;

    if (this.selectedModDateFrom === undefined && this.selectedModDateTo === undefined) {
      params = {changedDateFrom: undefined, changedDateTo: undefined};
    } else if (this.selectedModDateFrom === undefined) {
      params = {changedDateFrom: undefined, changedDateTo: this.activeModDateTo};
    } else if (this.selectedModDateTo === undefined) {
      params = {changedDateFrom: this.activeModDateFrom, changedDateTo: undefined};
    } else {
      params = {changedDateFrom: this.activeModDateFrom, changedDateTo: this.activeModDateTo};
    }

    this.modDateChanged.emit(params);
  }

  updateEmptyModDateData() {
    this.getDateData();
  }

  changeModDateCollapseForTablet() {
    this.activeModDateCollapseTablet = !this.activeModDateCollapseTablet;
  }

}
