import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';

export interface CreatedDateChangedEvent {
  createdDateFrom: string;
  createdDateTo: string;
}

export interface NextEvaluationChangedEvent {
  nextEvaluationFrom: string;
  nextEvaluationTo: string;
  nextEvaluationNull: string;
}

export interface ChangedDateChangedEvent {
  changedDateFrom: string;
  changedDateTo: string;
}

@Component({
  selector: 'app-adura-date-side',
  templateUrl: './adura-date-side.component.html'
})
export class AduraDateSideComponent implements OnInit, OnChanges {
  @Input() activeCreatedDateFrom = '';
  @Input() activeCreatedDateTo = '';
  @Output() createdDateChanged = new EventEmitter<CreatedDateChangedEvent>();

  @Input() activeNextEvaluationFrom = '';
  @Input() activeNextEvaluationTo = '';
  @Input() activeNextEvaluationNull = '';
  @Output() nextEvaluationChanged = new EventEmitter<NextEvaluationChangedEvent>();

  @Input() activeChangedDateFrom = '';
  @Input() activeChangedDateTo = '';
  @Output() changedDateChanged = new EventEmitter<ChangedDateChangedEvent>();

  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: 'de'
  };

  selectedCreatedDateFrom: any;
  selectedCreatedDateTo: any;
  selectedNextEvaluationFrom: any;
  selectedNextEvaluationTo: any;
  nextEvalNullChecked: boolean;
  selectedChangedDateFrom: any;
  selectedChangedDateTo: any;

  collapse = true;

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
    this.datePickerConfig.locale = this.translateService.currentLang;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // CreatedDate
    if (this.activeCreatedDateFrom) {
      this.selectedCreatedDateFrom = dayjs(this.activeCreatedDateFrom);
    } else {
      this.activeCreatedDateFrom = '';
      delete this.selectedCreatedDateFrom;
    }

    if (this.activeCreatedDateTo) {
      this.selectedCreatedDateTo = dayjs(this.activeCreatedDateTo);
    } else {
      this.activeCreatedDateTo = '';
      delete this.selectedCreatedDateTo;
    }

    // NextEvaluation
    if (this.activeNextEvaluationFrom) {
      this.selectedNextEvaluationFrom = dayjs(this.activeNextEvaluationFrom);
    } else {
      this.activeNextEvaluationFrom = '';
      delete this.selectedNextEvaluationFrom;
    }

    if (this.activeNextEvaluationTo) {
      this.selectedNextEvaluationTo = dayjs(this.activeNextEvaluationTo);
    } else {
      this.activeNextEvaluationTo = '';
      delete this.selectedNextEvaluationTo;
    }

    this.nextEvalNullChecked = this.activeNextEvaluationNull === 'true';

    // ChangedDate
    if (this.activeChangedDateFrom) {
      this.selectedChangedDateFrom = dayjs(this.activeChangedDateFrom);
    } else {
      this.activeChangedDateFrom = '';
      delete this.selectedChangedDateFrom;
    }

    if (this.activeChangedDateTo) {
      this.selectedChangedDateTo = dayjs(this.activeChangedDateTo);
    } else {
      this.activeChangedDateTo = '';
      delete this.selectedChangedDateTo;
    }
  }

  getCreatedDateFromEvent(event: any) {
    this.selectedCreatedDateFrom = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getCreatedDateFrom();
  }

  getCreatedDateFrom() {
    if (this.selectedCreatedDateFrom) {
      this.activeCreatedDateFrom = this.selectedCreatedDateFrom.format('YYYY-MM-DD');
      this.getCreatedDateData();
    }
  }

  getCreatedDateToEvent(event: any) {
    this.selectedCreatedDateTo = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getCreatedDateTo();
  }

  getCreatedDateTo() {
    if (this.selectedCreatedDateTo) {
      this.activeCreatedDateTo = this.selectedCreatedDateTo.format('YYYY-MM-DD');
      this.getCreatedDateData();
    }
  }

  getCreatedDateData() {
    let params;

    if (this.selectedCreatedDateFrom === undefined && this.selectedCreatedDateTo === undefined) {
      params = { createdDateFrom: undefined, createdDateTo: undefined };
    } else if (this.selectedCreatedDateFrom === undefined) {
      params = { createdDateFrom: undefined, createdDateTo: this.activeCreatedDateTo };
    } else if (this.selectedCreatedDateTo === undefined) {
      params = { createdDateFrom: this.activeCreatedDateFrom, createdDateTo: undefined };
    } else {
      params = { createdDateFrom: this.activeCreatedDateFrom, createdDateTo: this.activeCreatedDateTo };
    }

    this.createdDateChanged.emit(params);
  }

  updateEmptyCreatedDateData() {
    this.getCreatedDateData();
  }

  getNextEvaluationFromEvent(event: any) {
    this.selectedNextEvaluationFrom = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getNextEvaluationFrom();
  }

  getNextEvaluationFrom() {
    if (this.selectedNextEvaluationFrom) {
      this.activeNextEvaluationFrom = this.selectedNextEvaluationFrom.format('YYYY-MM-DD');
      // Checkbox zurücksetzen
      this.nextEvalNullChecked = false;
      this.getNextEvaluationData();
    }
  }

  getNextEvaluationToEvent(event: any) {
    this.selectedNextEvaluationTo = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getNextEvaluationTo();
  }

  getNextEvaluationTo() {
    if (this.selectedNextEvaluationTo) {
      this.activeNextEvaluationTo = this.selectedNextEvaluationTo.format('YYYY-MM-DD');
      // Checkbox zurücksetzen
      this.nextEvalNullChecked = false;
      this.getNextEvaluationData();
    }
  }

  getNextEvaluationData() {
    let params;

    if (this.selectedNextEvaluationFrom === undefined && this.selectedNextEvaluationTo === undefined) {
      params = { nextEvaluationFrom: undefined, nextEvaluationTo: undefined };
    } else if (this.selectedNextEvaluationFrom === undefined) {
      params = { nextEvaluationFrom: undefined, nextEvaluationTo: this.activeNextEvaluationTo };
    } else if (this.selectedNextEvaluationTo === undefined) {
      params = { nextEvaluationFrom: this.activeNextEvaluationFrom, nextEvaluationTo: undefined };
    } else {
      params = { nextEvaluationFrom: this.activeNextEvaluationFrom, nextEvaluationTo: this.activeNextEvaluationTo };
    }

    // Nur TRUE wird geschickt
    if (this.nextEvalNullChecked) {
      params.nextEvaluationNull = this.nextEvalNullChecked;
    }
    this.nextEvaluationChanged.emit(params);
  }

  changeNextEvalNullValue(event) {
    this.nextEvalNullChecked = event.target.checked;

    // Wenn wir alle Steckbriefe ohne Datum gewählt haben, werden die Datumswerte rausgelöscht
    if (this.nextEvalNullChecked) {
      this.selectedNextEvaluationFrom = undefined;
      this.selectedNextEvaluationTo = undefined;
    }
    this.getNextEvaluationData();
  }

  nextEvalNullActive() {
    return this.nextEvalNullChecked;
  }

  updateEmptyNextEvaluationData() {
    this.getNextEvaluationData();
  }

  getChangedDateFromEvent(event: any) {
    this.selectedChangedDateFrom = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getChangedDateFrom();
  }

  getChangedDateFrom() {
    if (this.selectedChangedDateFrom) {
      this.activeChangedDateFrom = this.selectedChangedDateFrom.format('YYYY-MM-DD');
      this.getChangedDateData();
    }
  }

  getChangedDateToEvent(event: any) {
    this.selectedChangedDateTo = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getChangedDateTo();
  }

  getChangedDateTo() {
    if (this.selectedChangedDateTo) {
      this.activeChangedDateTo = this.selectedChangedDateTo.format('YYYY-MM-DD');
      this.getChangedDateData();
    }
  }

  getChangedDateData() {
    let params;

    if (this.selectedChangedDateFrom === undefined && this.selectedChangedDateTo === undefined) {
      params = { changedDateFrom: undefined, changedDateTo: undefined };
    } else if (this.selectedChangedDateFrom === undefined) {
      params = { changedDateFrom: undefined, changedDateTo: this.activeChangedDateTo };
    } else if (this.selectedChangedDateTo === undefined) {
      params = { changedDateFrom: this.activeChangedDateFrom, changedDateTo: undefined };
    } else {
      params = { changedDateFrom: this.activeChangedDateFrom, changedDateTo: this.activeChangedDateTo };
    }

    this.changedDateChanged.emit(params);
  }

  updateEmptyChangedDateData() {
    this.getChangedDateData();
  }

  collapseFilter() {
    this.collapse = !this.collapse;
  }
}
