<div *ngIf="showSpinner" class="spinner">
  <div class="splash-screen-spinner">
    <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
  </div>
</div>
<div class="row admin tabmenu-content-freigabe">
  <div class="col-sm-12 freigabe-filter-parent">
    <div class="col-sm-12 freigabe-filter">
      <h2 (click)="collapseFilterList()" attr.aria-label="{{ 'global.date' | translate }}">Filter
        <i class="fa" [ngClass]="collapseFilter? 'fa-angle-right': 'fa-angle-down'"></i></h2>
      <div *ngIf="!collapseFilter">
        <div class="col-sm-4 clear-padding-left">
          <app-adura-massenfreigabe-date-filter [activeCreatedDateFrom]="createdDateFrom" [activeCreatedDateTo]="createdDateTo"
                                                (createdDateChanged)="onCreatedDateChanged($event)"
                                                [activeChangedDateFrom]="changedDateFrom" [activeChangedDateTo]="changedDateTo"
                                                (changedDateChanged)="onChangedDateChanged($event)"></app-adura-massenfreigabe-date-filter>

        </div>
        <div class="col-sm-3 clear-padding-left">
          <app-adura-massenfreigabe-seismo-info-filter [seismoPub]="seismoPubFacets" [activeSeismoPub]="seismoPub" (seismoPubChanged)="onSeismoPubChanged($event)"
                                                       [seismoInfo]="seismoInfoFacets" [activeSeismoInfo]="seismoInfo"
                                                       (seismoInfoChanged)="onSeismoInfoChanged($event)"></app-adura-massenfreigabe-seismo-info-filter>
          <app-adura-massenfreigabe-status-filter [status]="statusFacets" [activeStatus]="status"
                                                  (statusChanged)="onStatusChanged($event)"></app-adura-massenfreigabe-status-filter>
        </div>
        <div class="col-sm-3 col-sm-offset-1 clear-padding-left">
          <app-adura-massenfreigabe-type-filter (reqFieldsChanged)="onMissingReqFieldsChanged($event)" [typeList]="typeList" (typeChanged)="onTypeChanged($event)"></app-adura-massenfreigabe-type-filter>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 title">
    <p>Anzahl Einträge: {{ resultList.length }} / {{ totalEntries }}
      <button class="btn-xs btn-default" role="button"
              title="{{ 'adura.freigabe.reloadList' | translate }}" [attr.aria-label]="'adura.freigabe.reloadList' | translate" (click)="reloadList()"><i class="fa fa-refresh"></i></button>
    </p>
  </div>
  <div class="col-sm-12 title">
    <div class="col-sm-0.5">
      <input type="checkbox" (change)="toggleAllSelected()"/>
    </div>
    <div class="col-sm-1 clear-padding-left">{{ 'global.type' | translate }}</div>
    <div class="col-sm-1">{{ 'adura.status.label' | translate }}</div>
    <div class="col-sm-2">{{ 'adura.title' | translate }}</div>
    <div class="col-sm-2">{{ 'adura.freigabe.kurzinfo' | translate }}</div>
    <div class="col-sm-1">{{ 'adura.meldung.sterne' | translate }}</div>
    <div class="col-sm-1">{{ 'adura.freigabe.bereich' | translate }}</div>
    <div class="col-sm-0.5">{{ 'adura.meldung.public' | translate }}</div>
    <div class="col-sm-1.5 clear-padding-right">{{ 'adura.meldung.seismoInfoAusgabe' | translate }}</div>
    <div class="col-sm-1.5">{{ 'adura.freigabe.lastChange' | translate }}</div>
  </div>
</div>
<div class="row tabmenu-content-freigabe" *ngFor="let entry of resultList"
     app-adura-massenfreigabe-entry
     [entry]="entry"
     [toggle]="toggleAllSubject.asObservable()"
     [error]="errorSubject.asObservable()"
     [success]="successSubject.asObservable()"
     (toggleMeldungEmitter)="toggleMeldungSelected($event)"
     (toggleSteckbriefEmitter)="toggleSteckbriefSelected($event)">
</div>
<button *ngIf="hasMoreEntries" type="button" class="btn btn-sm" (click)="nextTranche()">
  {{ 'global.loadMore' | translate }}
</button>
<div class="row thema-preview">
  <div class="col-sm-12">
    <div class="col-sm-7">
      <label class="col-sm-4">{{ 'adura.meldung.seismoInfoAusgabe' | translate }}:</label>
      <div class="col-sm-8 adura-formular">
        <select class="form-control" id="seismoInfoAusgabe" name="seismoInfoAusgabe" [(ngModel)]="seismoInfoAusgabe">
          <option [ngValue]="undefined" selected>{{ 'global.choose' | translate }}</option>
          <option *ngFor="let seismoInfo of seismoInfoList" [ngValue]="seismoInfo">
            {{ seismoInfo.month | date: 'MM/yyyy' }} ({{ seismoInfo.freigegebenCount + '/' + seismoInfo.meldungCount }})
          </option>
        </select>
      </div>
    </div>
    <span class="col-sm-2.5 col-sm-offset-0.5">
      <button (click)="getSeismoInfoPreview()" class="btn btn-sm btn-default" [disabled]="isSeismoInfoPreviewDisabled()">
        <i class="fa fa-play fa-fw" [ngClass]="'fa-eye'"></i> {{ 'adura.meldung.seismoInfoPreview' | translate }}
      </button>
    </span>
    <span class="col-sm-1.5 col-sm-offset-0.5">
      <button (click)="auswahlFreigeben()" class="btn btn-sm btn-default">
        <i class="fa" [ngClass]="sendingFreigabe?'fa-spinner fa-pulse':'fa-check'"></i> {{ 'adura.freigabe.freigeben' | translate }}
      </button>
    </span>
  </div>
</div>
<app-document-preview-modal on-download="getSeismoInfoDocument()"></app-document-preview-modal>
