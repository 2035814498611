<header class="page-header" *ngIf="!papierkorbView">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <button type="button" class="btn btn-sm btn-space btn-primary" onclick="history.back()">
          <i class="fa fa-backward"></i>
        </button>
        <button type="button" class="btn btn-sm btn-space btn-primary" (click)="editMeldung()"
                title="{{ 'adura.meldung.edit' | translate }}" *ngIf="isEditable()">
          <i class="fa fa-edit"></i>
        </button>
        <app-link-clipboard title="{{ meldung?.titel }}" type="{{ 'adura.meldung.label' | translate }}"
                            buttonClass="btn btn-sm btn-space btn-primary"></app-link-clipboard>
      </div>
    </div>
  </div>
</header>

<section class="container information">
  <ng-container *ngIf="id; else meldungNotFound">
    <h1 [innerHTML]="titel"></h1>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.meldung.id' | translate }}</label>
      <div class="col-sm-10">
        {{ meldung.code }}
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.erfDate' | translate }} / {{ 'adura.mutDate' | translate }}</label>
      <div class="col-sm-10">
        {{ meldung.erfDate | date: 'dd.MM.yyyy' }} / {{ meldung.mutDate | date: 'dd.MM.yyyy' }}
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.shortInfo' | translate }}</label>
      <div class="col-sm-10 bullets" [innerHTML]="meldung.kurzinfo"></div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.kommentar' | translate }}</label>
      <div class="col-sm-10 bullets" [innerHTML]="meldung.kommentar"></div>
    </div>

    <hr>

    <h3>{{ 'adura.publication.plural' | translate }}</h3>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.publication.baseSource' | translate }}</label>
      <div class="col-sm-10">
        <div *ngIf="basisQuelle">
          <app-adura-publikation-entry [pubDetailType]="10" [pubDetail]="basisQuelle"></app-adura-publikation-entry>
        </div>
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.publication.others' | translate }}</label>
      <div class="col-sm-10">
        <ul class="adura-form" *ngIf="originalPublikationen.length > 0">
          <li *ngFor="let originalPublikation of originalPublikationen">
            <app-adura-publikation-entry [pubDetailType]="20" [pubDetail]="originalPublikation"></app-adura-publikation-entry>
          </li>
        </ul>
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.furtherInformation.title' | translate }}</label>
      <div class="col-sm-10">
        <ul class="adura-form" *ngIf="weitereInfos.length > 0">
          <li *ngFor="let weitereInfoEntry of weitereInfos">
            <a href="{{ weitereInfoEntry.link }}" target="_blank">{{ weitereInfoEntry.info }}</a>
          </li>
        </ul>
      </div>
    </div>

    <hr>

    <h3>{{ 'adura.links' | translate }}</h3>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.danger.label' | translate }}</label>
      <div class="col-sm-10">
        <ul class="adura-form">
          <li *ngFor="let gefahr of meldung.gefahren" [innerHTML]="gefahr.bezeichnung"></li>
        </ul>
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.matrix.label' | translate }}</label>
      <div class="col-sm-10">
        <ul class="adura-form">
          <li *ngFor="let matrix of meldung.matrix">{{ matrix.sortierung }} - {{ matrix.bezeichnung }}
            <ng-container *ngIf="matrix.ergaenzung"> ({{ matrix.ergaenzung }})</ng-container>
          </li>
        </ul>
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.driver.label' | translate }}</label>
      <div class="col-sm-10">
        <ul class="adura-form">
          <li *ngFor="let treiber of meldung.treiber">{{ treiber.bezeichnung }}</li>
        </ul>
      </div>
    </div>

    <div class="row form-element">
      <div class="col-sm-12 clear-padding-left clear-padding-right">
        <label class="col-sm-2">{{ 'adura.domain.label' | translate }}</label>
        <label class="col-sm-3">{{ 'adura.domain.primaer' | translate }}</label>
        <label class="col-sm-7">{{ 'adura.domain.sekundaer' | translate }}</label></div>
      <ng-container *ngFor="let bereich of meldung.bereiche">
        <div class="col-sm-12 clear-padding-left clear-padding-right">
          <div class="col-sm-2">{{ bereich.bezeichnung }}</div>
          <div class="col-sm-3">
            <input type="checkbox" [checked]="bereich.primaer" disabled/>
          </div>
          <div class="col-sm-7">
            <input type="checkbox" [checked]="true" disabled/>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.steckbrief.label' | translate }}</label>
      <div class="col-sm-10">
        <ul class="adura-form">
          <li *ngFor="let steckbrief of meldung.steckbriefe">
            <a [routerLink]='["/adura/factsheet/", steckbrief.code]' [innerHTML]="steckbrief.code + ' - ' + steckbrief.titel"></a>
          </li>
        </ul>
      </div>
    </div>

    <hr>

    <h3>{{ 'adura.meldung.seismoInfo' | translate }}</h3>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.meldung.seismoPublication' | translate }}</label>
      <div class="col-sm-10">{{ 'global.boolean.' + meldung.seismoPublikation | translate }}</div>
    </div>
    <div class="row" *ngIf="this.meldung.seismoPublikation">
      <label class="col-sm-2">{{ 'adura.meldung.seismoInfoAusgabe' | translate }}</label>
      <div class="col-sm-10" *ngIf="this.seismoInfo">
        {{ seismoInfo.month | date: 'MM/yyyy' }} ({{ seismoInfo.freigegebenCount + '/' + seismoInfo.meldungCount }})
      </div>
    </div>

    <hr>

    <h3>{{ 'adura.status.processControl' | translate }}</h3>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.status.label' | translate }}</label>
      <div class="col-sm-10">
        {{ this.meldung.status.messageProperty | translate }}
      </div>
    </div>

    <div class="row">
      <label class="col-sm-2">{{ 'adura.meldung.publicVisible' | translate }}</label>
      <div class="col-sm-10">
        <input type="checkbox"
               role="checkbox"
               disabled
               [checked]="meldung?.isPublic"/>
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.meldung.sterne' | translate }}</label>
      <div class="col-sm-10 star-rating">
        <app-rating-config [initialRating]="meldung.sterne" [readOnly]="true"></app-rating-config>
      </div>
    </div>

    <hr>

    <app-adura-tracelog [code]="meldung.code"></app-adura-tracelog>
  </ng-container>
  <ng-template #meldungNotFound>
    <h1>{{ 'adura.meldung.notFound' | translate }}</h1>
  </ng-template>
</section>
