import {
  Component,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef, ViewRef
} from '@angular/core';
import * as dayjs from 'dayjs';
import { Event, EventSave, SubeventSave } from '../../shared/classes/event';
import { AwisaFoService } from '../../shared/services/awisa-fo.service';
import { TranslateService } from '@ngx-translate/core';
import { SubeventNewComponent } from '@app/event/event-new/subevent-new/subevent-new.component';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Role } from '@app/shared/classes/role';
import { Tag } from '@app/shared/classes/tag';
import { User } from '@app/core/auth/user';
import { SettingsService } from '@app/core/config/settings.service';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-termin-create',
  templateUrl: './event-new.component.html'
})
export class EventNewComponent implements OnInit {
  @ViewChild('subevents', {read: ViewContainerRef}) container;

  eventForm: UntypedFormGroup;
  tags: Tag[];
  roles: Role[];
  languages: string [];
  event: Event;
  user: User;
  html = '';
  currentAwisaLanguage: string;

  newEventEnabled = false;

  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: ''
  };
  selectedDateFrom: dayjs.Dayjs;
  selectedDateTo: dayjs.Dayjs;
  dateFrom: string;
  dateTo: string;

  isSending = false;
  error = false;

  constructor(private awisaFoService: AwisaFoService,
              private translateService: TranslateService,
              private authenticationService: AuthenticationService,
              private formBuilder: UntypedFormBuilder,
              private settingsService: SettingsService,
              private router: Router) {
  }

  ngOnInit(): void {
    const set = this.settingsService.settings().find(el => el.key === 'gui.add.event.enabled');
    if (set.value === '1') {
      this.newEventEnabled = true;
    }
    const lang = this.translateService.currentLang;
    const key = 'gui.roles.disclaimer.' + lang;
    const set2 = this.settingsService.settings().find(el => el.key === key);
    this.html = set2.value;

    this.user = this.authenticationService.getUser();

    this.currentAwisaLanguage = this.translateService.currentLang;
    this.languages = this.translateService.getLangs();
    this.changeDisplayLanguageCalendar();
    this.awisaFoService.getTags()
      .subscribe(
        (result) => {
          this.tags = result;
        });
    this.awisaFoService.getRoles()
      .subscribe(
        (result) => {
          console.log(result);
          this.roles = result;
        });
    this.initForm();
  }

  private initForm() {
    this.eventForm = this.formBuilder.group({
      name: ['', Validators.required],
      dateFrom: [''],
      dateTo: [''],
      timeFrom: ['', [
        Validators.required,
        Validators.pattern('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')]],
      timeTo: ['', [
        Validators.required,
        Validators.pattern('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')]],
      tags: [[], Validators.required],
      desc_de: [''],
      desc_en: [''],
      desc_fr: [''],
      desc_it: [''],
      roles: new UntypedFormArray(
        [new UntypedFormControl('BLV_AWISA_ROLLE_Veterinaerdienst'),
          new UntypedFormControl('BLV_AWISA_ROLLE_Lebensmittelbehoerde')], Validators.required),
      subEvents: new UntypedFormArray([])
    });
  }

  get rolesForm() {
    return this.eventForm.get('roles') as UntypedFormArray;
  }

  get subEvents() {
    return this.eventForm.get('subEvents') as UntypedFormArray;
  }

  addSubEvent() {
    const subEventComponent: ComponentRef<SubeventNewComponent> = this.container.createComponent(SubeventNewComponent);

    const viewRef: ViewRef = subEventComponent.hostView;

    this.subEvents.push(subEventComponent.instance.initForm());

    console.log(this.eventForm.controls);

    subEventComponent.instance.deleted.subscribe(() => {
      this.deleteSubEventComponent(this.container.indexOf(viewRef));
    });
  }

  deleteSubEventComponent(index: number) {
    this.container.remove(index);
    this.subEvents.removeAt(index);
    console.log(this.eventForm.controls);
  }

  changeDisplayLanguageCalendar() {
    this.datePickerConfig.locale = dayjs.locale(this.currentAwisaLanguage);
  }

  getDateFromFromEvent(event: any) {
    this.selectedDateFrom = dayjs(event.target.value.split('.').reverse().join('-'));
  }

  getDateToFromEvent(event: any) {
    this.selectedDateTo = dayjs(event.target.value.split('.').reverse().join('-'));
  }

  getDateFrom() {
    // Else sollte nicht möglich sein, da das Feld required ist
    if (this.selectedDateFrom) {
      this.dateFrom = this.selectedDateFrom.format('YYYY-MM-DD');
    }
  }

  getDateTo() {
    // Else sollte nicht möglich sein, da das Feld required ist
    if (this.selectedDateTo) {
      this.dateTo = this.selectedDateTo.format('YYYY-MM-DD');
    }
  }

  createTermin() {
    this.isSending = true;
    const formValues = this.eventForm.value;
    const subEvents = [];
    let invalidSubEvent = false;

    let daten: EventSave;

    if (formValues.subEvents) {
      formValues.subEvents.forEach(elem => {
        if (elem.dateTo < elem.dateFrom) {
          invalidSubEvent = true;
        } else {
          const subDateFrom = dayjs(elem.dateFrom).format('YYYY-MM-DD');
          const subDateTo = dayjs(elem.dateTo).format('YYYY-MM-DD');
          const subEvent: SubeventSave = {
            dateFrom: subDateFrom.concat(' ', elem.timeFrom),
            dateTo: subDateTo.concat(' ', elem.timeTo),
            descriptionDe: elem.desc_de,
            descriptionEn: elem.desc_en,
            descriptionFr: elem.desc_fr,
            descriptionIt: elem.desc_it
          };
          subEvents.push(subEvent);
        }
      });
    }

    if (this.dateFrom && this.dateTo) {
      daten = {
        name: formValues.name,
        dateFrom: this.dateFrom.concat(' ', formValues.timeFrom),
        dateTo: this.dateTo.concat(' ', formValues.timeTo),
        descriptionDe: formValues.desc_de,
        descriptionEn: formValues.desc_en,
        descriptionFr: formValues.desc_fr,
        descriptionIt: formValues.desc_it,
        tags: formValues.tags,
        subtermine: subEvents,
        roles: formValues.roles,
        einbringerGuid: this.user.guid
      };

      console.log(daten);
      console.log(this.eventForm.controls);
    }

    if (!this.eventForm.controls.dateFrom.value) {
      this.eventForm.controls.dateFrom.setErrors({required: true});
    }

    if (!this.eventForm.controls.dateTo.value) {
      this.eventForm.controls.dateTo.setErrors({required: true});
    }

    if (this.eventForm.valid && !this.invalidDateRange() && !invalidSubEvent) {
      this.awisaFoService.createTermin(daten)
        .subscribe({
          next: (result) => {
            this.isSending = false;
            this.onReset();
            this.error = false;
            console.log(result);
            this.showSuccessAlert('event.stored');
            this.router.navigateByUrl('/');
          },
          error: error => {
            this.isSending = false;
            this.error = true;
            console.log(error);
          }
        });
    } else {
      Object.keys(this.eventForm.controls).forEach(field => {
        const control = this.eventForm.get(field);
        control.markAsDirty({onlySelf: true});
        console.log(this.eventForm.controls);
        console.log(typeof (this.eventForm.controls.dateFrom.value));
      });
      this.isSending = false;
    }
  }

  chooseRole(event) {
    /* Selected */
    if (event.target.checked) {
      this.rolesForm.push(new UntypedFormControl(event.target.name));
    }  /* unselected */
    else {
      let i = 0;
      this.rolesForm.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value === event.target.name) {
          this.rolesForm.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  isRoleActive(role: string) {
    const roleExist = this.rolesForm.controls.find(
      (res: UntypedFormControl) => res.value === role);
    if (roleExist) {
      return roleExist;
    } else {
      return false;
    }
  }

  onReset() {
    this.eventForm.reset();
    this.eventForm.removeControl('roles');
    this.eventForm.addControl('roles', new UntypedFormArray([new UntypedFormControl('BLV_AWISA_ROLLE_Veterinaerdienst'),
      new UntypedFormControl('BLV_AWISA_ROLLE_Lebensmittelbehoerde')], Validators.required));
  }

  invalidDateRange() {
    return this.eventForm.controls.dateTo.value < this.eventForm.controls.dateFrom.value;
  }

  showSuccessAlert(translateString: string){
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
        });
  }
}
