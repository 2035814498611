import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';

export interface DateChangedEvent {
  dateFrom: string;
  dateTo: string;
}

@Component({
  selector: 'app-date-side',
  templateUrl: './date-side.component.html'
})
export class DateSideComponent implements OnChanges {
  @Input() activeDateFrom = '';
  @Input() activeDateTo = '';
  @Output() dateChanged = new EventEmitter<DateChangedEvent>();

  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: ''
  };
  selectedDateFrom: any;
  selectedDateTo: any;
  activeDateCollapseTablet = false;
  currentAwisaLanguage: string;

  constructor(private translateService: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.activeDateFrom) {
      this.selectedDateFrom = dayjs(this.activeDateFrom);
    } else {
      this.activeDateFrom = '';
      delete this.selectedDateFrom;
    }

    if (this.activeDateTo) {
      this.selectedDateTo = dayjs(this.activeDateTo);
    } else {
      this.activeDateTo = '';
      delete this.selectedDateTo;
    }

    this.currentAwisaLanguage = this.translateService.currentLang;
    this.changeDisplayLanguageCalendar();
  }

  changeDisplayLanguageCalendar() {
    this.datePickerConfig.locale = dayjs.locale(this.currentAwisaLanguage);
  }

  getDateFromFromEvent(event: any) {
    this.selectedDateFrom = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getDateFrom();
  }

  getDateFrom() {
    if (this.selectedDateFrom) {
      this.activeDateFrom = this.selectedDateFrom.format('YYYY-MM-DD');
      this.getDateData();
    }
  }

  getDateToFromEvent(event: any) {
    this.selectedDateTo = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getDateTo();
  }

  getDateTo() {
    if (this.selectedDateTo) {
      this.activeDateTo = this.selectedDateTo.format('YYYY-MM-DD');
      this.getDateData();
    }
  }

  getDateData() {
    let params;

    if (this.selectedDateFrom === undefined && this.selectedDateTo === undefined) {
      params = {dateFrom: undefined, dateTo: undefined};
    } else if (this.selectedDateFrom === undefined) {
      params = {dateFrom: undefined, dateTo: this.activeDateTo};
    } else if (this.selectedDateTo === undefined) {
      params = {dateFrom: this.activeDateFrom, dateTo: undefined};
    } else {
      params = {dateFrom: this.activeDateFrom, dateTo: this.activeDateTo};
    }

    this.dateChanged.emit(params);
  }

  updateEmptyDateData() {
    this.getDateData();
  }

  changeDateCollapseForTablet() {
    this.activeDateCollapseTablet = !this.activeDateCollapseTablet;
  }
}
