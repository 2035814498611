<header class="page-header front-header">
  <div class="container admin">
    <div class="row">
      <div class="col-md-6">
        <h1>{{ 'admin.label' | translate }}</h1>
      </div>
      <div class="col-md-5">
        <div class="col-md-12"><b>App-Versionen (awisa-)</b></div>
        <div class="col-md-5">Frontend:</div>
        <div class="col-md-7">{{ getFrontendVersion }}</div>
        <div class="col-md-5">Backend:</div>
        <div class="col-md-7">{{ getAwisaFOVersion }}</div>
        <div class="col-md-5">DataExchange:</div>
        <div class="col-md-7">{{ getDataExchangeVersion }}</div>
        <div class="col-md-5">Mailer:</div>
        <div class="col-md-7">{{ getMailerVersion }}</div>
        <div class="col-md-5">adura:</div>
        <div class="col-md-7">{{ getAduraVersion }}</div>
      </div>
    </div>
  </div>
</header>

<div class="admin-content" role="main">
  <section id="explore" class="container">
    <app-tabs (tabChanged)="onCategoryChange($event)" [selectedId]="currentCategory">
      <app-tab tabId="job" tabTitle="Jobs" contentClass="tabmenu-content">
        <div class="row admin">
          <div class="col-md-7"></div>
          <div class="col-md-1"></div>
          <div class="col-md-2"><p class="admin">Letzter Erfolg</p></div>
          <div class="col-md-2"></div>
        </div>
        <div class="row admin">
          <div class="col-md-7"><p class="admin"><b>Alle Jobs ausführen</b></p></div>
          <div class="col-md-1">
            <button [disabled]="runningAllJobs" (click)="runAllJobs()" class="btn btn-default">
              <i class="fa fa-play fa-fw" [ngClass]="runningAllJobs?'fa-spinner fa-pulse':'fa-play'"></i>
            </button>
          </div>
          <div class="col-md-2">
            <p class="admin"><b>{{ runAllTask.finishedAt | date: 'dd.MM.yyyy  HH:mm:ss' }}</b></p>
          </div>
          <div class="col-md-2">
            <p class="admin"><b>{{ runAllTask.finishedAt | dateAgo }}</b></p>
          </div>
        </div>
        <div class="row admin">
          <div class="col-md-7"><p class="admin">Expertensuche AwisaChanges wird angestossen</p></div>
          <div class="col-md-1">
            <button [disabled]="loadingFetchChanges" (click)="fetchAwisaChanges()" class="btn btn-sm btn-default">
              <i class="fa fa-play fa-fw" [ngClass]="loadingFetchChanges?'fa-spinner fa-pulse':'fa-play'"></i>
            </button>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ listAwisaChangesTask.finishedAt | date: 'dd.MM.yyyy  HH:mm:ss' }}</p>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ listAwisaChangesTask.finishedAt | dateAgo }}</p>
          </div>
        </div>
        <div class="row admin">
          <div class="col-md-7"><p class="admin">Geänderte Inhalte (Dokument, Event, Link) werden eingelesen</p></div>
          <div class="col-md-1">
            <button [disabled]="loadingDocuments" (click)="getDocuments()" class="btn btn-sm btn-default">
              <i class="fa fa-play fa-fw" [ngClass]="loadingDocuments?'fa-spinner fa-pulse':'fa-play'"></i>
            </button>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ fetchDocumentsTask.finishedAt | date: 'dd.MM.yyyy  HH:mm:ss' }}</p>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ fetchDocumentsTask.finishedAt | dateAgo }}</p>
          </div>
        </div>
        <div class="row admin">
          <div class="col-md-7"><p class="admin">Ordnerstruktur aktualisieren (Domänen, Oberthemen, Themen, Dokumentübersetzungen)</p></div>
          <div class="col-md-1">
            <button [disabled]="loadingStructure" (click)="getStructure()" class="btn btn-sm btn-default">
              <i class="fa fa-play fa-fw" [ngClass]="loadingStructure?'fa-spinner fa-pulse':'fa-play'"></i>
            </button>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ makeStructureTask.finishedAt | date: 'dd.MM.yyyy  HH:mm:ss' }}</p>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ makeStructureTask.finishedAt | dateAgo }}</p>
          </div>
        </div>
        <div class="row admin">
          <div class="col-md-7"><p class="admin">Übersetzungen laden (Schlagwörter und andere Katalogwerte)</p></div>
          <div class="col-md-1">
            <button [disabled]="loadingFetchTranslations" (click)="fetchTranslations()" class="btn btn-sm btn-default">
              <i class="fa fa-play fa-fw" [ngClass]="loadingFetchTranslations?'fa-spinner fa-pulse':'fa-play'"></i>
            </button>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ fetchTranslationsTask.finishedAt | date: 'dd.MM.yyyy  HH:mm:ss' }}</p>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ fetchTranslationsTask.finishedAt | dateAgo }}</p>
          </div>
        </div>
        <div class="row admin">
          <div class="col-md-7"><p class="admin">Links in Themen-Word verarbeiten</p></div>
          <div class="col-md-1">
            <button [disabled]="loadingFetchProcessThemaWordLinks" (click)="fetchProcessThemaWordLinks()" class="btn btn-sm btn-default">
              <i class="fa fa-play fa-fw"
                 [ngClass]="loadingFetchProcessThemaWordLinks?'fa-spinner fa-pulse':'fa-play'"></i>
            </button>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ processThemaWordLinksTask.finishedAt | date: 'dd.MM.yyyy  HH:mm:ss' }}</p>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ processThemaWordLinksTask.finishedAt | dateAgo }}</p>
          </div>
        </div>
        <div class="row admin">
          <div class="col-md-7"><p class="admin">Lucene-Index neu generieren</p></div>
          <div class="col-md-1">
            <button [disabled]="createIndex" (click)="createLuceneIndex()" class="btn btn-sm btn-default">
              <i class="fa fa-play fa-fw" [ngClass]="createIndex?'fa-spinner fa-pulse':'fa-play'"></i>
            </button>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ createIndexTask.finishedAt | date: 'dd.MM.yyyy  HH:mm:ss' }}</p>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ createIndexTask.finishedAt | dateAgo }}</p>
          </div>
        </div>
        <div class="admin-divider"></div>
        <div class="row admin">
          <div class="col-md-7"><p class="admin">User aus EIAM aktualisieren</p></div>
          <div class="col-md-1">
            <button [disabled]="loadingUpdateUsers" (click)="updateEiamUsers()" class="btn btn-sm btn-default">
              <i class="fa fa-play fa-fw" [ngClass]="loadingUpdateUsers?'fa-spinner fa-pulse':'fa-play'"></i>
            </button>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ updateUsersTask.finishedAt | date: 'dd.MM.yyyy  HH:mm:ss' }}</p>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ updateUsersTask.finishedAt | dateAgo }}</p>
          </div>
        </div>
        <div class="row admin">
          <div class="col-md-7"><p class="admin">Abo-E-Mails verschickt</p></div>
          <div class="col-md-1">
            <button  [disabled]="loadingProcessSubscriptions" (click)="processSubscriptions()" class="btn btn-sm btn-default">
              <i class="fa fa-play fa-fw" [ngClass]="loadingProcessSubscriptions?'fa-spinner fa-pulse':'fa-play'"></i>
            </button>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ processSubscriptionsTask.finishedAt | date: 'dd.MM.yyyy  HH:mm:ss' }}</p>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ processSubscriptionsTask.finishedAt | dateAgo }}</p>
          </div>
        </div>
      </app-tab>
      <app-tab tabId="settings" tabTitle="Settings" contentClass="tabmenu-content">
        <app-admin-settings></app-admin-settings>
      </app-tab>
      <app-tab tabId="log" tabTitle="Log/Datenbank" contentClass="tabmenu-content">
        <div class="row admin">
          <div class="col-md-6 log-period"><p class="admin log-period">Job-Log-Einträge als CSV anzeigen</p>
            <select class="log-period" id="select-log-period" name="sort" [(ngModel)]="selectedPeriod">
              <option selected value="1">24h</option>
              <option value="7">Letzte Woche</option>
              <option value="30">Letzter Monat</option>
              <option value="365">Letztes Jahr</option>
              <option value="0">gesamt</option>
            </select>
          </div>
          <div class="col-md-3">
            <button (click)="getLogFile()" class="btn btn-sm btn-default">Job-Logs herunterladen</button>
          </div>
        </div>
        <div class="row admin">
          <div class="col-md-6 log-period"><p class="admin log-period">Fachliche Log-Einträge als CSV anzeigen</p>
            <select class="log-period" id="select-content-log-period" name="sort" [(ngModel)]="selectedContentPeriod">
              <option selected value="1">24h</option>
              <option value="7">Letzte Woche</option>
              <option value="30">Letzter Monat</option>
              <option value="365">Letztes Jahr</option>
              <option value="0">gesamt</option>
            </select>
          </div>
          <div class="col-md-3">
            <button (click)="getContentLogFile()" class="btn btn-sm btn-default">Fachliche Logs herunterladen</button>
          </div>
        </div>
        <div class="row admin">
          <div class="col-md-6"><p class="admin">Nicht zugeordnete Dokumente</p></div>
          <div class="col-md-3">
            <button (click)="getUnclassifiedDocuments()" class="btn btn-sm btn-default">
              CSV-Datei herunterladen
            </button>
          </div>
        </div>
        <div class="row admin">
          <div class="col-md-6">
            <p class="admin">
              {{ 'admin.document.evaluation' | translate }}
            </p>
          </div>
          <div class="col-md-3">
            <button (click)="getDocumentsForEvaluation()" class="btn btn-sm btn-default">
              CSV-Datei herunterladen
            </button>
          </div>
        </div>
        <hr>
        <div class="row admin">
          <div class="col-md-6"><p class="admin">Datenbank komplett löschen (ACHTUNG!!)</p></div>
          <div class="col-md-3">
            <button [disabled]="loadingTruncateDB" (click)="truncateDatabase()" class="btn btn-sm btn-default"
                    style="border-color: red; background: red; color: white;">
              !! DATENBANK löschen !!
            </button>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ procTruncateDatabase }}</p>
          </div>
        </div>
        <div class="row admin">
          <div class="col-md-6"><p class="admin">Dokumente in DB</p></div>
          <div class="col-md-3">
            <button (click)="docsInDb()" class="btn btn-sm btn-default">
              Abfragen
            </button>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ docsInDbResult }}</p>
          </div>
        </div>
        <hr>
        <div class="row admin">
          <div class="col-md-6"><p class="admin">Testmail verschicken</p></div>
          <div class="col-md-3">
            <button (click)="testmail()" class="btn btn-sm btn-default">
              Senden
            </button>
          </div>
          <div class="col-md-2">
            <p class="admin">{{ testmailResult }}</p>
          </div>
        </div>
      </app-tab>
      <app-tab tabId="check" tabTitle="Checks" contentClass="tabmenu-content">
        <app-admin-check></app-admin-check>
      </app-tab>
    </app-tabs>
  </section>
</div>

<div class="hidden">
  <div #truncateModal>
    <input id="truncatePerson" type="checkbox" class="hidden" [(ngModel)]="truncatePerson"/>
    <label for="truncatePerson">incl. Person-Tabelle</label>
  </div>
</div>
