import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-subevent-new',
  templateUrl: './subevent-new.component.html'
})
export class SubeventNewComponent implements OnInit {
  @Output() deleted = new EventEmitter();
  @Input() eventForm = UntypedFormGroup;

  subEventForm: UntypedFormGroup = this.initForm();

  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: ''
  };
  selectedDateTo: dayjs.Dayjs;
  selectedDateFrom: dayjs.Dayjs;
  dateFrom: string;
  dateTo: string;

  languages: string [];

  constructor(private translateService: TranslateService,
              private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.languages = this.translateService.getLangs();
  }

  public initForm(): UntypedFormGroup {
    this.subEventForm = this.formBuilder.group({
      dateFrom: [''],
      dateTo: [''],
      timeFrom: ['', [
        Validators.pattern('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')]],
      timeTo: ['', [
        Validators.pattern('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')]],
      desc_de: [''],
      desc_en: [''],
      desc_fr: [''],
      desc_it: [''],
    });
    return this.subEventForm;
  }

  deleteSubEventComponent() {
    this.deleted.emit();
  }

  getDateFromFromEvent(event: any) {
    this.selectedDateFrom = dayjs(event.target.value.split('.').reverse().join('-'));
  }

  getDateToFromEvent(event: any) {
    this.selectedDateTo = dayjs(event.target.value.split('.').reverse().join('-'));
  }

  validateTwoDates() {
    return this.subEventForm.controls.dateTo.value < this.subEventForm.controls.dateFrom.value;
  }
}
