import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AduraFacet } from '@app/shared/classes/adura/adura-facet';
import * as dayjs from 'dayjs';

export interface BeurteilungDateChangedEvent {
  beurteilungDateFrom: string;
  beurteilungDateTo: string;
}

@Component({
  selector: 'app-adura-beurteilung-side',
  templateUrl: './adura-beurteilung-side.component.html'
})
export class AduraBeurteilungSideComponent implements OnChanges {
  @Input() beurteilungType: AduraFacet[] = [];
  @Input() activeBeurteilungType: string[] = [];
  @Output() beurteilungTypeChanged = new EventEmitter<string[]>();

  @Input() activeBeurteilungDateFrom = '';
  @Input() activeBeurteilungDateTo = '';
  @Output() beurteilungDateChanged = new EventEmitter<BeurteilungDateChangedEvent>();

  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: 'de'
  };
  selectedBeurteilungDateFrom: any;
  selectedBeurteilungDateTo: any;
  currentAwisaLanguage: string;

  collapse = false;

  constructor(private translateService: TranslateService) {
  }

  isBeurteilungTypeActive(id: string) {
    return this.activeBeurteilungType.find(res => res === id);
  }

  changeBeurteilungType(id: string) {
    const newActiveBeurteilungType = this.activeBeurteilungType.slice();
    id = id.replace('beurteilung_type_', '');
    const index = newActiveBeurteilungType.indexOf(id);
    if (index > -1) {
      newActiveBeurteilungType.splice(index, 1);
    } else {
      newActiveBeurteilungType.push(id);
    }
    this.beurteilungTypeChanged.emit(newActiveBeurteilungType);
  }

  collapseFilter() {
    this.collapse = !this.collapse;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.activeBeurteilungDateFrom) {
      this.selectedBeurteilungDateFrom = dayjs(this.activeBeurteilungDateFrom);
    } else {
      this.activeBeurteilungDateFrom = '';
      delete this.selectedBeurteilungDateFrom;
    }

    if (this.activeBeurteilungDateTo) {
      this.selectedBeurteilungDateTo = dayjs(this.activeBeurteilungDateTo);
    } else {
      this.activeBeurteilungDateTo = '';
      delete this.selectedBeurteilungDateTo;
    }

    this.currentAwisaLanguage = this.translateService.currentLang;
    this.changeDisplayLanguageCalendar();
  }

  changeDisplayLanguageCalendar() {
    this.datePickerConfig.locale = dayjs.locale(this.currentAwisaLanguage);
  }

  getBeurteilungDateFromEvent(event: any) {
    this.selectedBeurteilungDateFrom = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getBeurteilungDateFrom();
  }

  getBeurteilungDateFrom() {
    if (this.selectedBeurteilungDateFrom) {
      this.activeBeurteilungDateFrom = this.selectedBeurteilungDateFrom.format('YYYY-MM-DD');
      this.getDateData();
    }
  }

  getBeurteilungDateToEvent(event: any) {
    this.selectedBeurteilungDateTo = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getBeurteilungDateTo();
  }

  getBeurteilungDateTo() {
    if (this.selectedBeurteilungDateTo) {
      this.activeBeurteilungDateTo = this.selectedBeurteilungDateTo.format('YYYY-MM-DD');
      this.getDateData();
    }
  }

  getDateData() {
    let params;

    if (this.selectedBeurteilungDateFrom === undefined && this.selectedBeurteilungDateTo === undefined) {
      params = { beurteilungDateFrom: undefined, beurteilungDateTo: undefined };
    } else if (this.selectedBeurteilungDateFrom === undefined) {
      params = { beurteilungDateFrom: undefined, beurteilungDateTo: this.activeBeurteilungDateTo };
    } else if (this.selectedBeurteilungDateTo === undefined) {
      params = { beurteilungDateFrom: this.activeBeurteilungDateFrom, beurteilungDateTo: undefined };
    } else {
      params = { beurteilungDateFrom: this.activeBeurteilungDateFrom, beurteilungDateTo: this.activeBeurteilungDateTo };
    }

    this.beurteilungDateChanged.emit(params);
  }

  updateEmptyBeurteilungDateData() {
    this.getDateData();
  }
}
