<section class="container information">
  <h1 *ngIf="id; else newMeldung" [innerHTML]="titel"></h1>
  <ng-template #newMeldung>
    <h1>{{ 'adura.meldung.new' | translate }}</h1>
  </ng-template>
  <form [formGroup]="meldungForm" (ngSubmit)="saveMeldung()">
    <div class="adura-formular">
      <ng-container *ngIf="id">
        <div class="row form-element">
          <label class="col-sm-2">
            {{ 'adura.meldung.id' | translate }}
          </label>
          <div class="col-sm-10">
            {{ this.code }}
          </div>
        </div>
        <div class="row form-element">
          <label class="col-sm-2">{{ 'adura.erfDate' | translate }} / {{ 'adura.mutDate' | translate }}</label>
          <div class="col-sm-10">
            {{ meldung.erfDate | date: 'dd.MM.yyyy' }} / {{ meldung.mutDate | date: 'dd.MM.yyyy' }}
          </div>
        </div>
      </ng-container>
      <div class="row form-element" [ngClass]="hasError('titel')?'alert alert-danger':''">
        <p *ngIf="hasError('titel')" class="col-sm-12 adura-error">{{ getError('titel') | translate }}</p>
        <label class="col-sm-2">{{ 'adura.title' | translate }}:</label>
        <div class="col-sm-10">
          <div class="tiny-input">
            <editor formControlName="titel"
                    [inline]="true"
                    [init]="tinyConfigTitel"
                    (onInit)="updateRemainingCharacters('titel', $event)"
                    (onKeyUp)="updateRemainingCharacters('titel', $event)"
                    (onChange)="updateRemainingCharacters('titel', $event)">
            </editor>
          </div>
          <span class="length-counter">
            {{ remainingCharactersMap['titel'] + ' / ' + maxCharsMap['titel'] }}
          </span>
        </div>
      </div>
      <div class="row form-element" [ngClass]="hasError('kurzinfo')?'alert alert-danger':''">
        <p *ngIf="hasError('kurzinfo')" class="col-sm-12 adura-error">{{ getError('kurzinfo') | translate }}</p>
        <label class="col-sm-12">{{ 'adura.shortInfo' | translate }}:</label>
        <div class="col-sm-12">
          <div class="tiny-textarea">
            <editor formControlName="kurzinfo"
                    [inline]="true"
                    [init]="tinyConfigEnterAndLinksAllowed"
                    (onInit)="updateRemainingCharacters('kurzinfo', $event)"
                    (onKeyUp)="updateRemainingCharacters('kurzinfo', $event)"
                    (onChange)="updateRemainingCharacters('kurzinfo', $event)">
            </editor>
          </div>
          <span class="length-counter">
            {{ remainingCharactersMap['kurzinfo'] + ' / ' + maxCharsMap['kurzinfo'] }}
          </span>
        </div>
      </div>
      <div class="row form-element">
        <label class="col-sm-12">{{ 'adura.kommentar' | translate }}:</label>
        <div class="col-sm-12">
          <div class="tiny-input">
            <editor formControlName="kommentar"
                    [inline]="true"
                    [init]="tinyConfigEnterAndLinksAllowed"
                    (onInit)="updateRemainingCharacters('kommentar', $event)"
                    (onKeyUp)="updateRemainingCharacters('kommentar', $event)"
                    (onChange)="updateRemainingCharacters('kommentar', $event)">
            </editor>
          </div>
          <span class="length-counter">
            {{ remainingCharactersMap['kommentar'] + ' / ' + maxCharsMap['kommentar'] }}
          </span>
        </div>
      </div>

      <hr>

      <h3>{{ 'adura.publication.plural' | translate }}</h3>
      <div class="row form-element" [ngClass]="hasError('publikationen')?'alert alert-danger':''">
        <p *ngIf="hasError('publikationen')" class="col-sm-12 adura-error">{{ getError('publikationen') | translate }}</p>
        <label class="col-sm-2">{{ 'adura.publication.baseSource' | translate }}:</label>
        <div class="col-sm-10">
          <div *ngIf="basisQuelle; else noBasisQuelleSelected">
            <app-adura-publikation-entry [pubDetailType]="10" [pubDetail]="basisQuelle"></app-adura-publikation-entry>
            <button type="button" class="favorite-icon" title="{{ 'adura.publication.edit' | translate }}"
                    (click)="openAddPublikation( 10, basisQuelle)" [attr.aria-label]="'adura.publication.edit' | translate">
              <i class="fa fa-pencil"></i>
            </button>
            <button type="button" class="favorite-icon" title="{{ 'adura.publication.remove' | translate }}"
                    (click)="removeBasisQuelle()" [attr.aria-label]="'adura.publication.remove' | translate">
              <i class="fa fa-remove"></i>
            </button>
          </div>
          <ng-template #noBasisQuelleSelected>
            <button type="button" class="favorite-icon" title="{{ 'adura.publication.add' | translate }}"
                    (click)="openAddPublikation(10)" [attr.aria-label]="'adura.publication.add' | translate">
              <i class="fa fa-plus"></i>
            </button>
          </ng-template>
        </div>
      </div>

      <div class="row form-element">
        <label class="col-sm-2">{{ 'adura.publication.others' | translate }}:</label>
        <div class="col-sm-10">
          <div *ngFor="let originalPublikation of originalPublikationen">
            <app-adura-publikation-entry [pubDetailType]="20" [pubDetail]="originalPublikation"></app-adura-publikation-entry>
            <button type="button" class="favorite-icon" title="{{ 'adura.publication.edit' | translate }}"
                    (click)="openAddPublikation( 20, originalPublikation)" [attr.aria-label]="'adura.publication.edit' | translate">
              <i class="fa fa-pencil"></i>
            </button>
            <button type="button" class="favorite-icon" title="{{ 'adura.publication.remove' | translate }}"
                    (click)="removeOriginalPublikation(originalPublikation)" [attr.aria-label]="'adura.publication.remove' | translate">
              <i class="fa fa-remove"></i>
            </button>
          </div>
          <button type="button" class="favorite-icon" title="{{ 'adura.publication.add' | translate }}"
                  (click)="openAddPublikation(20)" [attr.aria-label]="'adura.publication.add' | translate">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>

      <app-adura-publikation-add (updateAddBasisQuelle)="reloadPublikationBasisquelle($event)"
                                 (updateAddOriginalPublikation)="updateOriginalPublikationenList($event)"></app-adura-publikation-add>

      <div class="row form-element">
        <label class="col-sm-2">{{ 'adura.furtherInformation.title' | translate }}:</label>
        <div class="col-sm-10">
          <ul class="adura-form" *ngIf="meldungForm.get('weitereInfos')?.value?.length > 0">
            <li *ngFor="let weitereInfoEntry of meldungForm.get('weitereInfos').value">
              <a href="{{ weitereInfoEntry.link }}" target="_blank">{{ weitereInfoEntry.info }}</a>
              <button type="button" class="favorite-icon" title="{{ 'global.edit' | translate }}"
                      (click)="openAddWeitereInfo(weitereInfoEntry)" [attr.aria-label]="'global.edit' | translate">
                <i class="fa fa-pencil"></i>
              </button>
              <button type="button" class="favorite-icon" title="{{ 'global.delete' | translate }}"
                      (click)="removeWeitereInfo(weitereInfoEntry)" [attr.aria-label]="'global.delete' | translate">
                <i class="fa fa-times"></i>
              </button>
            </li>
          </ul>
          <button type="button" class="favorite-icon" title="{{ 'adura.furtherInformation.add' | translate }}"
                  (click)="openAddWeitereInfo()" [attr.aria-label]="'adura.furtherInformation.add' | translate">
            <i class="fa fa-plus"></i>
          </button>
          <app-meldung-weitere-info (updateAddWeitereInfo)="updateWeitereInfoList($event)"></app-meldung-weitere-info>
        </div>
      </div>

      <hr>

      <h3>{{ 'adura.links' | translate }}</h3>
      <div class="row form-element">
        <label class="col-sm-2">{{ 'adura.danger.label' | translate }}:
          <a role="button" title="{{ 'adura.danger.create' | translate }}" (click)="openCreateGefahr()"
             [attr.aria-label]="'adura.danger.create' | translate"><i class="fa fa-plus" aria-hidden="true"></i></a>
        </label>
        <app-adura-gefahr-create (updateCreateGefahr)="reloadGefahrList()"></app-adura-gefahr-create>
        <div class="col-sm-10">
          <ng-select formControlName="gefahren"
                     placeholder="{{ 'adura.danger.choose' | translate }}"
                     [items]="gefahrList"
                     [multiple]="true"
                     dropdownPosition="bottom"
                     bindLabel="bezeichnungRaw">
            <ng-template ng-label-tmp let-item="item">
              <span aria-hidden="true" class="ng-value-icon left" (click)="removeGefahr(item)">×</span>
              <span class="ng-value-label" [innerHTML]="item.bezeichnung"></span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
              <span [innerHTML]="item.bezeichnung"></span>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row form-element">
        <label class="col-sm-2">{{ 'adura.matrix.label' | translate }}:</label>
        <div class="col-sm-10">
          <ul class="adura-form" *ngIf="meldungForm.get('matrix')?.value?.length > 0">
            <li *ngFor="let matrixEntry of meldungForm.get('matrix').value">
              <app-adura-matrix-entry [matrixDetail]="matrixEntry"></app-adura-matrix-entry>
              <button type="button" class="favorite-icon" title="{{ 'global.edit' | translate }}"
                      (click)="openEditMatrix(matrixEntry)" [attr.aria-label]="'global.edit' | translate">
                <i class="fa fa-pencil"></i>
              </button>
              <button type="button" class="favorite-icon" title="{{ 'global.delete' | translate }}"
                      (click)="removeMatrix(matrixEntry)" [attr.aria-label]="'global.delete' | translate">
                <i class="fa fa-times"></i>
              </button>
            </li>
          </ul>
          <button type="button" class="favorite-icon" title="{{ 'adura.matrix.add' | translate }}"
                  (click)="openAddMatrix()" [attr.aria-label]="'adura.matrix.add' | translate">
            <i class="fa fa-plus"></i>
          </button>
          <app-adura-matrix-edit [meldung]="meldung" (updateAddMatrix)="updateMatrixList($event)"></app-adura-matrix-edit>
        </div>
      </div>
      <div class="row form-element">
        <label class="col-sm-2">{{ 'adura.driver.label' | translate }}:
          <a role="button" title="{{ 'adura.driver.create' | translate }}" (click)="openCreateTreiber()"
             [attr.aria-label]="'adura.driver.create' | translate"><i class="fa fa-plus" aria-hidden="true"></i></a>
        </label>
        <app-adura-treiber-create (updateCreateTreiber)="reloadTreiberList()"></app-adura-treiber-create>
        <div class="col-sm-10">
          <ng-select formControlName="treiber"
                     placeholder="{{ 'adura.driver.choose' | translate }}"
                     [items]="treiberList"
                     [multiple]="true"
                     dropdownPosition="bottom"
                     bindLabel="bezeichnung">
          </ng-select>
        </div>
      </div>

      <div class="row form-element" [ngClass]="hasError('bereiche')?'alert alert-danger':''">
        <div class="col-sm-12 clear-padding-left clear-padding-right">
          <p *ngIf="hasError('bereiche')" class="col-sm-12 adura-error">{{ getError('bereiche') | translate }}</p>
          <label class="col-sm-2">{{ 'adura.domain.label' | translate }}</label>
          <label class="col-sm-3">{{ 'adura.domain.primaer' | translate }}</label>
          <label class="col-sm-7">{{ 'adura.domain.sekundaer' | translate }}</label>
        </div>
        <ng-container *ngFor="let bereich of bereichList">
          <div class="col-sm-12 clear-padding-left clear-padding-right">
            <div class="col-sm-2">{{ bereich.bezeichnung }}</div>
            <div class="col-sm-3 adura-form">
              <input type="checkbox"
                     class="primary-domain-checkbox"
                     [value]="bereich.id"
                     [checked]="isBereichActiveAndPrimary(bereich.id)"
                     [disabled]="bereiche?.length > 0 && isPrimaryBereichSelected() && !isBereichActiveAndPrimary(bereich.id)"
                     (click)="onPrimaryDomainChange($event)"/>
            </div>
            <div class="col-sm-7 adura-form">
              <input type="checkbox"
                     class="secondary-domain-checkbox"
                     [value]="bereich.id"
                     [checked]="bereichActive(bereich.id)"
                     (click)="onSecondaryDomainChange($event)"/>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row form-element">
        <label class="col-sm-2">{{ 'adura.steckbrief.label' | translate }}:</label>
        <div class="col-sm-10">
          <ng-select formControlName="steckbriefe"
                     placeholder="{{ 'adura.steckbrief.choose' | translate }}"
                     [items]="steckbriefList"
                     [multiple]="true"
                     dropdownPosition="bottom"
                     bindLabel="codeTitelRaw"
                     (change)="setHasSteckbrief($event)">
            <ng-template ng-label-tmp let-item="item">
              <span aria-hidden="true" class="ng-value-icon left" (click)="removeSteckbrief(item)">×</span>
              <span class="ng-value-label" [innerHTML]="item.code + ' - ' + item.titel"></span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
              <span [innerHTML]="item.code + ' - ' + item.titel"></span>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <hr>

      <h3>{{ 'adura.meldung.seismoInfo' | translate }}</h3>
      <div class="row form-element">
        <label class="col-sm-2">{{ 'adura.meldung.seismoPublication' | translate }}:</label>
        <div class="col-sm-10 adura-form">
          <label [attr.aria-checked]="">
            <input type="radio"
                   formControlName="seismoPublikation"
                   [value]="true"
                   role="radio"
                   (click)="loadSeismoInfoList(true)">
            {{ 'global.yes' | translate }}
          </label>
          <label class="ml-1" [attr.aria-checked]="">
            <input type="radio"
                   formControlName="seismoPublikation"
                   [value]="false"
                   role="radio"
                   (click)="loadSeismoInfoList()">
            {{ 'global.no' | translate }}
          </label>
        </div>
      </div>
      <div class="row" *ngIf="publishSeismoInfo()">
        <label class="col-sm-2">{{ 'adura.meldung.seismoInfoAusgabe' | translate }}:</label>
        <div class="col-sm-10 adura-form">
          <select class="form-control adura-form" id="seismoInfoAusgabe" name="seismoInfoAusgabe" formControlName="seismoInfoAusgabe">
            <option *ngFor="let seismoInfo of seismoInfoList" [ngValue]="seismoInfo.month">
              {{ seismoInfo.month | date: 'MM/yyyy' }} ({{ seismoInfo.freigegebenCount + '/' + seismoInfo.meldungCount }})
            </option>
          </select>
        </div>
      </div>

      <hr>

      <h3>{{ 'adura.status.processControl' | translate }}</h3>
      <ng-container *ngIf="id">
        <div class="row form-element">
          <label class="col-sm-2">{{ 'adura.status.label' | translate }}:</label>
          <div class="col-sm-10">
            <ng-select formControlName="status"
                       placeholder="{{ 'adura.status.choose' | translate }}"
                       [items]="statusList"
                       [clearable]="false"
                       dropdownPosition="bottom">
              <ng-template ng-label-tmp let-item="item">
                <span>{{ item.messageProperty | translate }}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span>{{ item.messageProperty | translate }}</span>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </ng-container>

      <div class="row" [ngClass]="hasError('isPublic')?'alert alert-danger':''">
        <p *ngIf="hasError('isPublic')" class="col-sm-12 adura-error">{{ getError('isPublic') | translate }}</p>
        <label class="col-sm-2">{{ 'adura.meldung.publicVisible' | translate }}</label>
        <div class="col-sm-10 adura-form">
          <input type="checkbox"
                 formControlName="isPublic"
                 role="checkbox"
                 [checked]="meldung?.isPublic"/>
        </div>
      </div>
      <div class="row form-element" [ngClass]="hasError('sterne')?'alert alert-danger':''">
        <p *ngIf="hasError('sterne')" class="col-sm-12 adura-error">{{ getError('sterne') | translate }}</p>
        <label class="col-sm-2">{{ 'adura.meldung.sterne' | translate }}</label>
        <div class="col-sm-10 star-rating">
          <app-rating-config [initialRating]="getRating()" (ratingChanged)="setRating($event)"></app-rating-config>
        </div>
      </div>
      <ng-container *ngIf="id">
        <div *ngIf="id" class="row form-element">
          <label class="col-sm-12">{{ 'adura.log.comment' | translate }}</label>
          <div class="col-sm-12">
            <input class="form-control adura-form"
                   formControlName="logComment"
                   id="logComment"
                   maxlength="255"
                   autocomplete="off">
            <span class="length-counter">
              {{ (255 - meldungForm.get('logComment').value?.length) + ' / 255' }}
            </span>
          </div>
        </div>
        <app-adura-tracelog [code]="meldung.code"></app-adura-tracelog>
      </ng-container>
    </div>
    <div class="row col-sm-12 mt-20">
      <p *ngIf="error" class="alert alert-danger" role="alert">{{ 'adura.meldung.errorSave' | translate}}</p>
      <p *ngIf="deleteError" class="alert alert-danger" role="alert">{{ 'adura.meldung.errorDelete' | translate}}</p>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button type="button" class="btn btn-sm btn-space btn-primary"
                [disabled]="meldungForm.invalid || this.isSending" (click)="saveMeldung()">
          <i *ngIf="isSending" class="fa fa-spinner fa-pulse fa-fw"></i>{{ 'global.save' | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-space btn-primary" *ngIf="!hasSteckbrief"
                [disabled]="meldungForm.invalid || this.isSending" (click)="saveMeldungCreateSteckbrief()">
          <i *ngIf="isSending" class="fa fa-spinner fa-pulse fa-fw"></i>{{ 'adura.meldung.saveCreateSteckbrief' | translate }}
        </button>
        <ng-template [appHasRole]="['AWISA_FRESIL']">
          <button type="button" class="btn btn-sm btn-space btn-danger pull-right" id="deleteButton" *ngIf="meldung?.id"
                  [disabled]="this.isSending" (click)="deleteMeldung()">
            <i *ngIf="isSending" class="fa fa-spinner fa-pulse fa-fw"></i>{{ 'global.delete' | translate }}
          </button>
        </ng-template>
        <button type="button" [routerLink]="cancelLink" class="btn btn-sm btn-space btn-secondary pull-right">
          {{ 'global.cancel' | translate }}
        </button>
      </div>
    </div>
  </form>
</section>
